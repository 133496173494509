import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { IconButton } from '@mui/material'
import { FaUserEdit } from 'react-icons/fa'
import { ChevronRight, Close } from '@mui/icons-material'
import { MdLogout, MdOutlineLockOpen } from 'react-icons/md'

import LoadingModal from "components/LoadingModal"
// import LanguageToggle from 'components/Input/langguageToggle'
import UpdateProfile from './updateProfile'
import ChangeCredential from './changeCredential'
import UserInfoHOC from './actions'

import { getTranslation } from 'utils/renderLanguage'
import User from 'assets/user.png'
import Logo from 'assets/logo-name-yellow.png'
import './index.scss'


const UserInfo = props => {
  
  const user = props.data?.currentUser??{}
  const [ showChangeCredential, setShowChangeCredential ] = useState( false )
  const [ showUpdateProfile, setShowUpdateProfile ] = useState( false )

  useEffect(() => {
    console.log( props.data )
  }, [])

  const homeRedirect = () => {
    switch( user.role ){
      case 'Admin':
        return '/admin/statistic'
      case 'Client':
        return '/client/statistic'
      default:
        return '/'
    }
  }

  return(
    <>
      <div className='topbar-container'>
        {
          user && (
            <>
              <div className='d-flex align-items-center'>
                <IconButton 
                  classes={{ root: props.sidebar ? 'toggle-menu' : 'toggle-menu-expand' }}
                  onClick={ () => props.setSidebar( !props.sidebar ) }>
                  {
                    props.sidebar ? <ChevronRight /> : <Close />
                  }
                </IconButton>
                <Link to={ homeRedirect() } className='logo-bars' >
                  <img style={{ width: '100%', height: 60 }} src={ Logo } alt={ 'logo' } />
                </Link>
              </div>
              <Dropdown className='d-flex align-items-center'>
                {/* <LanguageToggle /> */}
                <Dropdown.Toggle className='user-button-container' variant="primary" id="dropdown-basic">
                  <img src={ User } alt={ 'user' } />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className={ 'user-info' }>
                    <p id={ 'display-name' }>{ user.displayName }</p>
                    <p id={ 'role' }>{ user.role }</p>
                  </div>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={ () => setShowUpdateProfile( true ) }>
                    <FaUserEdit style={{ marginRight: 10, width: 14 }} />
                    { getTranslation( props.language, 'Update Profile' ) }
                  </Dropdown.Item>
                  <Dropdown.Item onClick={ () => setShowChangeCredential( true ) }>
                    <MdOutlineLockOpen style={{ marginRight: 10, width: 14 }} />
                    { getTranslation( props.language, 'Change Email/Password' ) }
                  </Dropdown.Item>
                  <Dropdown.Item 
                    onClick={ () => props.signOutUser() }>
                    <MdLogout style={{ marginRight: 10, width: 14 }}/>
                    { getTranslation( props.language, 'Sign Out' ) }
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )
        }
      </div>
      {
        showChangeCredential && <ChangeCredential 
          setOpen={ setShowChangeCredential }
          changeUserCredential={ props.changeUserCredential }
          user={ user }
        />
      }
      {
        showUpdateProfile && <UpdateProfile
          setOpen={ setShowUpdateProfile }
          updateProfile={ props.updateProfile }
          user={ user }
        />
      }
      { props.onLoading && <LoadingModal />}
    </>
  )
}

export default UserInfoHOC( UserInfo );