import React, { useEffect } from "react";
import { LuUsers, LuEdit3 } from 'react-icons/lu'

import LoadingModal from "components/LoadingModal";
import PaginatedTable from "components/PaginatedTable";
import ModuleHeader from 'components/Card/ModuleHeader';
import DeleteConfirmation from 'components/Card/Confirmation';
import ValidationModal from './validation'
import CreateForm from './form'
import UpdateForm from './form'

import UserManagementHOC from './actions';

const columnData = [
  { header: 'Display Name', accessor: 'displayName' },
  { header: 'Email', accessor: 'email' },
  { header: 'Mobile No.', accessor: 'phone_number' },
  { header: 'Role', containerStyle: { width: '60%' }, accessor: 'role' },
  { header: 'Verified', containerStyle: { width: '60%' }, renderColumn: rowData => rowData.is_verified
    ? <span className='badge badge-success'>Verified</span>
    : <span className='badge badge-danger'>Not Verified</span> },
]

const UserManagement = ({
  users,
  selectedUser,
  showUpdateModal,
  showDeleteModal,
  showCreateModal,
  showValidationModal,
  onLoadUserManagement,

  getUsers,
  createUser,
  updateUser,
  deleteUser,
  validateUser,
  getSelectedUser,
  onChangeUserManagementHOC
}) => {

  useEffect(() => {
    getUsers()
  }, [])

  return(
    <>
      <ModuleHeader
        title={ 'User Management' }
        renderIcon={ () => <LuUsers /> }
        description={
          <>
            This module is used to manage users and roles.
            <ul style={{ paddingLeft: 30 }}>
              <li>Create, Edit, and Termiate users.</li>
              <li>Change user role.</li>
              <li>View user</li>
            </ul>
          </>
        }
        actionButton={[{
            label: 'Create User',
            className: 'btn btn-primary',
            onClick: () => onChangeUserManagementHOC( true, 'showCreateModal' )
        }]} />
      {
        users.length > 0 && <PaginatedTable
          rowData={ users || [] }
          columnData={ columnData }
          className={ 'mt-2' }
          actionsColumn={[{
            name: 'Edit',
            renderActionIcon: <LuEdit3 />,
            onClick: rowData => getSelectedUser( rowData.uid, 'edit' )
          }, {
            name: 'Validate',
            style: { marginRight: 10 },
            onShow: rowData => !rowData.is_verified && rowData.role_id !== 1,
            className: 'btn-success',
            onClick: rowData => getSelectedUser( rowData.uid, 'validate' )
          }, {
            name: 'Delete',
            style: { marginRight: 10 },
            className: 'btn-danger',
            onClick: rowData => Promise.all([
              onChangeUserManagementHOC( rowData, 'selectedUser' )
            ]).then( () => {
              onChangeUserManagementHOC( true, 'showDeleteModal' )
            })
          }]}
        />
      }
      {
        showCreateModal && <CreateForm
          mode={ 'create' }
          setOpen={ val => onChangeUserManagementHOC( val, 'showCreateModal' ) }
          onClickSubmit={ dataToSubmit => createUser( dataToSubmit ) } />
      }
      {
        showUpdateModal && <UpdateForm
          mode={ 'update' }
          selectedUser={ selectedUser }
          setOpen={ val => onChangeUserManagementHOC( val, 'showUpdateModal' ) }
          onClickSubmit={ dataToSubmit => updateUser( dataToSubmit ) } />
      }
      {
        showValidationModal && <ValidationModal
          selectedUser={ selectedUser }
          setOpen={ val => onChangeUserManagementHOC( val, 'showValidationModal' ) }
          onClickSubmit={ dataToSubmit => validateUser( dataToSubmit ) } />
      }
      <DeleteConfirmation
        isOpen={ showDeleteModal }
        confirmationText={ `Are you sure you want to delete ${ selectedUser.displayName }?` }
        setOpen={ val => onChangeUserManagementHOC( val, 'showDeleteModal' ) }
        onClick={ () => deleteUser( selectedUser.uid ) } />
      { onLoadUserManagement && <LoadingModal /> }
    </>
  )
}

export default UserManagementHOC( UserManagement );