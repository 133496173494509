import { position, education, license, industrial, subIndustiral,
  experienceYear, achievement } from "utils/dictionary";

export const freelancerTabs = [
  { label: 'Basic Information', eventKey: 'basic_information' },
  { label: 'Bank Details', eventKey: 'bank_details' },
  { label: 'Position Preferred', eventKey: 'position_preffered' },
  { label: 'Language Profile', eventKey: 'language_profile' },
  { label: 'Job Experience', eventKey: 'job_experience' },
  { label: 'Certification', eventKey: 'certification' },
  { label: 'Achievement', eventKey: 'achievement' },
]

export const clientTabs = [
  { label: 'Basic Information', eventKey: 'basic_information' },
  { label: 'SSM Information', eventKey: 'ssm_information' },
  { label: 'Offer Letter', eventKey: 'offer_letter' },
]

export const fieldData = [
  { label: 'First Name (Company Name)', value: 'first_name' },
  { label: `Last Name (PIC's Name)`, value: 'last_name' },
  { label: 'Email Address', value: 'email_address' },
  { label: 'Identity Card (PIC)', value: 'identity_card' },
  { label: 'Phone Number (PIC)', value: 'phone_number' },
  { label: 'Address (Company)', value: 'address' },
  { label: 'Mailing Address', value: 'mailing_address' },
]

export const freelanceFieldData = [
  { label: 'Date of Birth', value: 'dob', type: 'date' },
  { label: 'Age', value: 'age', type: 'number' },
  { label: 'Highest Education', value: 'highest_education', options: education },
  { label: 'License', value: 'license', options: license },
]

export const clientFieldData = [
  { label: 'Industry', value: 'industry', options: industrial },
  { label: 'Sub-Industry', value: 'sub_industrial', options: subIndustiral },
  { label: 'Year of Experience', value: 'year_of_experience', options: experienceYear },
]

export const bankFields = [
  { label: 'Bank Name', value: 'bank_name' },
  { label: 'Bank Account Holder Name', value: 'bank_account_holder' },
  { label: 'Bank Account Number', value: 'bank_account_number' },
] 

export const positionField = [
  { label: 'Position', value: 'position', options: position },
  { label: 'Expected Salary', value: 'expected_salary' },
  { label: 'Work Duration(Days)', value: 'work_duration' },
  { label: 'Remark (Optional)', value: 'remarks' }
]

export const languageField = [
  { label: 'English', value: 'en' },
  { label: 'Bahasa Malaysia', value: 'bm' },
  { label: 'Chinese', value: 'ch' },
]

export const jobFieldData = [
  { label: 'Employment Type', value: 'employment_type' },
  { label: 'Title/Occupation', value: 'occupation' },
  { label: 'Company', value: 'company' }, 
  { label: 'Start Date', value: 'start_date' }, 
  { label: 'End Date', value: 'end_date' }, 
  { label: 'Description (Optional)', value: 'description' },
]

export const achievementField = [
  { label: 'Achievement', value: 'achievement_type', options: achievement },
  { label: 'Date', value: 'date' },
  { label: 'Description (Optional)', value: 'description' },
  { label: 'Document', value: 'document' }
]

export const certificateField = [
  { label: 'Certificate', value: 'certificate_type' },
  { label: 'Expiry Date', value: 'expiry_date' },
  { label: 'Description (Optional)', value: 'description' },
  { label: 'Document', value: 'document' }
]