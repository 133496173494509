import { combineReducers } from 'redux';

import TemplateReducer from './template'
import CurrentUserReducer from './currentUser'

const reducers = combineReducers({
  template: TemplateReducer,
  currentUser: CurrentUserReducer
})

export default reducers;