import React, { useEffect } from "react";
import { LuFileSignature, LuEdit3 } from 'react-icons/lu'

import LoadingModal from "components/LoadingModal";
import PaginatedTable from "components/PaginatedTable";
import ModuleHeader from 'components/Card/ModuleHeader';
import DeleteConfirmation from 'components/Card/Confirmation';
import CreateSubscription from './form'
import UpdateSubscription from './form'

import SubscriptionManagementHOC from './actions';
import EmptyState from "components/EmptyState";

const columnData = [
  { header: 'Name', accessor: 'name' },
  { header: 'Description', accessor: 'description' },
  { header: 'Price / Unit', renderColumn: rowData => `RM ${ rowData.price } ${ rowData.price_unit }` },
  { header: 'Status', containerStyle: { width: '60%' }, renderColumn: rowData => Number( rowData.status ) === 1
    ? <span className='badge badge-success'>Active</span>
    : <span className='badge badge-danger'>{ rowData.status }</span> },
]

const SubscriptionManagement = ({
  subscriptions,
  showUpdateModal,
  showDeleteModal,
  showCreateModal,
  selectedSubscription,
  onLoadSubManagement,

  getSubscriptions,
  updateSubscription,
  createSubscription,
  deleteSubscription,
  getSelectedSubscription,
  onChangeSubManagementHOC,
}) => {

  useEffect( () => {
    getSubscriptions()
  }, [] )
  
  return(
    <>
      <ModuleHeader
        title={ 'Subscription Management' }
        renderIcon={ () => <LuFileSignature /> }
        description={
          <>
            This module is used to manage all the subscription plans and subscribed user.
            <ul style={{ paddingLeft: 30 }}>
              <li>Create, Edit, and Delete subscription plan.</li>
              <li>Change subscribed user.</li>
              <li>View subscription plan details.</li>
            </ul>
          </>
        }
        actionButton={[{
            label: 'Create Subscription',
            className: 'btn btn-primary',
            onClick: () => onChangeSubManagementHOC( true, 'showCreateModal' )
        }]} />
      {
        subscriptions.length > 0 && <PaginatedTable
          rowData={ subscriptions || [] }
          columnData={ columnData }
          className={ 'mt-2' }
          actionsColumn={[{
            name: 'Edit',
            renderActionIcon: <LuEdit3 />,
            onClick: rowData => getSelectedSubscription( rowData.id )
          }, {
            name: 'Delete',
            style: { marginRight: 10 },
            className: 'btn-danger',
            onClick: rowData => Promise.all([
              onChangeSubManagementHOC( rowData, 'selectedSubscription' )
            ]).then( () => {
              onChangeSubManagementHOC( true, 'showDeleteModal' )
            }
          )}]}
        />
      }
      {
        subscriptions.length === 0 && <EmptyState
          title={ 'No Subscription' }
          description={
            <>
              There is no subscription plan yet. Please create one.
              <button
                className='btn btn-primary w-100 mt-2'
                onClick={ () => onChangeSubManagementHOC( true, 'showCreateModal' ) }>
                Create Subscription
              </button>
            </>
          }/>
      }
      {
        showCreateModal && <CreateSubscription
          mode={ 'create' }
          setOpen={ val => onChangeSubManagementHOC( val, 'showCreateModal' ) }
          onClickSubmit={ createSubscription }
        />
      }
      {
        showUpdateModal && <UpdateSubscription
          mode={ 'update' }
          setOpen={ val => onChangeSubManagementHOC( val, 'showUpdateModal' ) }
          onClickSubmit={ updateSubscription }
          selectedSubscription={ selectedSubscription }
        />
      }
      <DeleteConfirmation
        isOpen={ showDeleteModal }
        setOpen={ val => onChangeSubManagementHOC( val, 'showDeleteModal' ) }
        confirmationText={ `Are you sure you want to delete ${ selectedSubscription.name }?` }
        onClick={ () => deleteSubscription( selectedSubscription.id ) } />
      { onLoadSubManagement && <LoadingModal /> }
    </>
  )
}

export default SubscriptionManagementHOC( SubscriptionManagement );