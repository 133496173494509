import React, {  useState } from "react";
import _ from 'lodash'
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Form, Modal, Button } from "react-bootstrap"

import ConfirmationModal from 'components/Card/Confirmation'

const fieldData = [
  { label: '', value: 'image_url' },
  { label: 'Title', value: 'title' },
  { label: 'Description', value: 'description' },
  { label: 'Price', value: 'price' },
  { label: 'Duration', value: 'duration' },
  { label: 'Tags', value: 'tag' },
  { label: 'Category', value: 'category' },
  { label: 'Language', value: 'language' },
  { label: 'Job Type', value: 'job_type' },
  { label: 'Job Description', value: 'job_desciption' },
  { label: 'Job Requiirement', value: 'job_requirement' },
  { label: 'Location', value: 'location' },
]

const JobListingForm = ({
  selectedJobListing,

  setOpen,
  onClickSubmit,
}) => {

  const [ showConfirmation, setShowConfirmation ] = useState( false )

  const onToggleSubmit = () => {
    const dataToSubmit = {
      id: selectedJobListing.id,
      status_id: 1,
      status: 'Active'
    }
    
    onClickSubmit( dataToSubmit )
    setShowConfirmation( false )
  }

  return(
    <>
      <Modal show={ true } size="lg" onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Job Listing Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            fieldData.map(( item, index ) => (
              <Form.Group key={ index } className="mb-3">
                <Form.Label>{ item.label }</Form.Label>
                {
                  item.value === 'image_url' && (
                    <img
                      src={ selectedJobListing[ item.value ] }
                      alt={ selectedJobListing.image_filename }
                      style={{ width: '100%' }} />
                  )
                }
                {
                  item.value === 'language' && (
                    <>
                      <p>English: { 
                        [1,2,3,4,5].map((_, index) => {
                          if(( index + 1 ) <= selectedJobListing?.language?.en ){
                            return <AiFillStar style={{ width: 20, height: 20, color: '#FDC500' }} />
                          } else {
                            return <AiOutlineStar style={{ width: 20, height: 20, color: '#a1a1aa' }} />
                          }
                        })
                      }</p>
                      <p>Chinese: { 
                        [1,2,3,4,5].map((_, index) => {
                          if(( index + 1 ) <= selectedJobListing?.language?.ch ){
                            return <AiFillStar style={{ width: 20, height: 20, color: '#FDC500' }} />
                          } else {
                            return <AiOutlineStar style={{ width: 20, height: 20, color: '#a1a1aa' }} />
                          }
                        })
                      }</p>
                      <p>Malay: { 
                        [1,2,3,4,5].map((_, index) => {
                          if(( index + 1 ) <= selectedJobListing?.language?.bm ){
                            return <AiFillStar style={{ width: 20, height: 20, color: '#FDC500' }} />
                          } else {
                            return <AiOutlineStar style={{ width: 20, height: 20, color: '#a1a1aa' }} />
                          }
                        })
                      }</p>
                    </>
                  )
                }
                {
                  !['image_url', 'language'].includes(item.value) && (
                    <p>{ selectedJobListing[ item.value ] }</p>
                  )
                }
              </Form.Group>
            ))
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Close
          </Button>
          {
            !selectedJobListing.status && (
              <Button
                variant="primary"
                onClick={() => setShowConfirmation( true )}>
                Verify
              </Button>
            )
          }
        </Modal.Footer>
      </Modal>
      <ConfirmationModal
        isOpen={ showConfirmation }
        setOpen={ val => setShowConfirmation( val ) }
        confirmationText={ `Are you sure you want to verify ${ selectedJobListing.title }?` }
        onClick={ () => onToggleSubmit() } />
    </>
  )
}

export default JobListingForm;