import React, { useEffect, useState } from "react";
import _ from 'lodash'
import { 
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import { Form, Modal, Button } from "react-bootstrap"

const fieldData = [
  { label: 'Poster/Image', value: 'image', type: 'file' },
  { label: 'Title', value: 'title', type: 'text' },
  { label: 'Description', value: 'description', type: 'text' },
  { label: 'Content', value: 'content', type: 'textarea' },
  { label: 'Status', value: 'status', type: 'radio', options: [
    { label: 'Active', value: 'Active' },
    { label: 'Draft', value: 'Darft' },
    { label: 'Inactive', value: 'Inactive' },
  ] }
]

const AnnouncementForm = ({
  mode,
  selectedAnnouncement,

  setOpen,
  onClickSubmit,
}) => {

  const [ formData, setFormData ] = useState({
    title: '',
    description: '',
    content: '',
    status: 'Active',
    image: '',
  })

  useEffect(() => {
    if (mode === 'update') {
      setFormData( selectedAnnouncement )
    }
  }, [mode, selectedAnnouncement])

  const onUploadImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let tmp = _.cloneDeep(formData.image)
      let files = event.target.files
      try {
        Object.keys(event.target.files).map((key) => {
          let reader = new FileReader()
          reader.onload = (e) => {
            tmp = {
              snapshot: e.target.result,
              snapshot_filename: files[key].name,
            }
            onChangeField(tmp, 'image')
          }
          reader.readAsDataURL(event.target.files[key])
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onChangeField = ( value, context ) => setFormData({ ...formData, [ context ]: value })

  const renderFieldInput = ( item ) => {
    switch( item.type ){
      case 'radio':
        return(
          <RadioGroup
            row
            value={ formData[ item.value ] }
            name={ `announcement-${ item.value }` }
            onChange={ e => onChangeField( e.target.value, item.value ) }>
            {
              item.options.map(( option, index ) => {
                return(
                  <FormControlLabel
                    key={ index }
                    value={ option.value }
                    control={ <Radio size="small" /> } label={ option.label } />
                )
              })
            }
          </RadioGroup>
        )
      case 'file':
        return(
          <>
            {
              ( formData[ item.value ] || formData.image_url ) && (
                <>
                  <img
                    alt={ 'announcement-image' }
                    src={ formData[ item.value ]?.snapshot ?? formData.image_url }
                    style={{ width: '100%', height: 200, objectFit: 'cover', borderRadius: 8 }} />
                  <p className='form-label'>{ formData[ item.value ]?.snapshot_filename ?? formData.image_filename }</p>
                  <button className='btn btn-danger' onClick={() => onChangeField( '', item.value ) }>Remove</button>
                </>
              )
            }
            {
              !( formData[ item.value ] || formData.image_url ) && (
                <Form.Control
                  type={ item.type }
                  style={{ height: 'fit-content' }}
                  multiple={ false }
                  accept="image/*"
                  onChange={ event =>  onUploadImage( event ) } />
              )
            }
          </>
        )
      case 'textarea':
        return(
          <Form.Control
            as="textarea"
            rows={ 3 }
            style={{ height: 100 }}
            placeholder={ item.placeholder || item.label }
            value={ formData[ item.value ] }
            onChange={(e) =>  onChangeField( e.target.value, item.value ) } />
        )
      default:
        return(
          <Form.Control
            type={ item.type }
            placeholder={ item.placeholder || item.label }
            value={ formData[ item.value ] }
            onChange={(e) =>  onChangeField( e.target.value, item.value ) } />
        )
    }
  }

  return(
    <Modal show={ true } onHide={() => setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{ _.capitalize( mode ) } Announcement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          fieldData.map(( item, index ) => (
            <Form.Group key={ index } className="mb-3">
              <Form.Label>{ item.label }</Form.Label>
              { renderFieldInput( item ) }
            </Form.Group>
          ))
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          disabled={ _.values( formData ).some( x => x === '' ) }
          variant="primary"
          onClick={() => onClickSubmit( formData )}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AnnouncementForm

