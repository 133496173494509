import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import CustomSnackbar from 'components/Snackbar';

import { storeItem } from 'utils/LocalStorage';
import { Get } from 'utils/axios';
import { UpdateCurrentUser } from 'reducers/currentUser';

const HOC = ( WrappedComponent ) => {

  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      showForgetPassword: false,
      loginCredential: {
        email: '',
        password: '',
      },
      recoverEmail: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
      },
      snackbarMessage: success
    }, () => { callback && callback() })
    onChangeLoginHOC = ( val, context ) => this.setState({ [ context ]: val })

    userLogin = () => {
      this.load( true )
      const auth = getAuth()
      const { email, password } = this.state.loginCredential

      signInWithEmailAndPassword( auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        this.getUserProfile( user.uid )
      })
      .catch((error) => {
        this.load( false )
        this.requestError( `Error: ${ error.code } - ${ error.message }` )
      });
    }

    getUserProfile = uid => Get(
      `/users-getUser?uid=${ uid }`,
      this.getUserProfileSuccess,
      this.getUserProfileError,
      this.load
    )
    getUserProfileSuccess = payload => {
      Promise.all([
        storeItem( this.props.type === 'admin'
          ? 'ADMIN_AUTHORIZATION_TOKEN'
          : 'CLIENT_AUTHORIZATION_TOKEN', payload.data.uid )
      ]).then( () => {
        this.props.UpdateCurrentUser( payload.data )
      })
    }
    getUserProfileError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            { ... this.state }
            onLoginLoad={ this.state.loading }

            userLogin={ this.userLogin }
            onChangeLoginHOC={ this.onChangeLoginHOC }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps, {
    UpdateCurrentUser
  } )( WithHOC );
}

export default HOC