import React from 'react';
import { GrUserWorker } from "react-icons/gr"
import { LuClipboardList, LuLayoutDashboard ,LuFileSignature, LuLayout, LuUsers, LuBriefcase } from 'react-icons/lu'

import { getTranslation } from 'utils/renderLanguage'

// export const SuperAdminItem = ( language ) => [
//   {
//     title: getTranslation( language, 'User Management' ),
//     path:'/admin/user-management',
//     icon: <MdPeople/>,
//   },
//   {
//     title: getTranslation( language, 'Project Management' ),
//     path: '/admin/project-management',
//     icon: <MdOutlineScience/>,
//   },
// ]

export const AdminItem = [
  {
    title: 'Statistic',
    path:'/admin/statistic',
    icon: <LuLayoutDashboard/>,
  },
  {
    title: 'User Management',
    path:'/admin/user-management',
    icon: <LuUsers/>,
  },
  {
    title: 'Subscription Management',
    path: '/admin/subscription',
    icon: <LuFileSignature/>,
  },
  {
    title: 'Announcement',
    path: '/admin/announcement',
    icon: <LuLayout/>,
  },
  {
    title: 'Job Listing',
    path: '/admin/job-listing',
    icon: <LuBriefcase/>,
  },
]

export const ClientItem = [
  {
    title: 'Statistic',
    path:'/client/statistic',
    icon: <LuLayoutDashboard/>,
  },
  {
    title: 'Listings',
    path: '/client/listings',
    icon: <LuClipboardList/>,
  },
  {
    title: 'Workes',
    path: '/client/workers',
    icon: <GrUserWorker/>,
  },
]