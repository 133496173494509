import React, { useEffect, useState } from "react";
import _ from 'lodash'
import { 
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import { Form, Modal, Button } from "react-bootstrap"

import CustomDatePicker from "components/Input/datepicker";

import { roleOptions, education, license, industrial, subIndustiral, experienceYear } from "utils/dictionary";

const basicData = [
  { label: 'First Name (Company Name)', value: 'first_name', type: 'text' },
  { label: `Last Name (PIC's Name)`, value: 'last_name', type: 'text' },
  { label: 'Email Address', value: 'email_address', type: 'text' },
  { label: 'Password', value: 'password', type: 'password' },
  { label: 'Re-enter Password', value: 're_enter_password', type: 'password' },
  { label: 'Identity Card (PIC)', value: 'identity_card', type: 'text' },
  { label: 'Phone Number (PIC)', value: 'phone_number', type: 'text' },
  { label: 'Address (Company)', value: 'address', type: 'text' },
  { label: 'Mailing Address', value: 'mailing_address', type: 'text' },
]

const freelanceFieldData = [
  { label: 'Date of Birth', value: 'dob', type: 'date' },
  { label: 'Age', value: 'age', type: 'number' },
  { label: 'Highest Education', value: 'highest_education', type: 'select', options: education },
  { label: 'License', value: 'license', type: 'select', options: license },
]

const clientFieldData = [
  { label: 'Industry', value: 'industry', type: 'select', options: industrial },
  { label: 'Sub-Industry', value: 'sub_industrial', type: 'select', options: subIndustiral },
  { label: 'Year of Experience', value: 'year_of_experience', type: 'select', options: experienceYear },
]
  

const UserForm = ({
  mode,
  selectedUser,

  setOpen,
  onClickSubmit,
}) => {

  const [ fieldData, setFieldData ] = useState([])
  const [ formData, setFormData ] = useState({
    first_name: '',
    last_name: '',
    identity_card: '',
    address: '',
    phone_number: '',
    email_address: '', 
    mailing_address: '',
    role_id: 1,
  })

  useEffect(() => {
    if( mode === 'update' ){
      setFieldData( basicData.filter( item => item.value !== 'password' && item.value !== 're_enter_password' ) )
      setFormData( selectedUser )
    } else {
      setFieldData( basicData )
    }
  }, [ selectedUser ])

  const onChangeField = ( value, context ) => setFormData({ ...formData, [ context ]: value })

  const renderFieldInput = ( item ) => {
    switch( item.type ){
      case 'select':
        return(
          <Form.Select
            value={ formData[ item.value ] }
            onChange={(e) =>  onChangeField( e.target.value, item.value ) }>
            {
              item.options.map(( option, index ) => (
                <option key={ index } value={ option.value }>{ option.label }</option>
              ))
            }
          </Form.Select>
        )
      // case 'date':
      //   return(
      //     // <CustomDatePicker
      //     //   value={ formData[ item.value ] || '' }
      //     //   onChange={ value => onChangeField( value, item.value ) } />
      //   )
      default:
        return(
          <Form.Control
            type={ item.type }
            placeholder={ item.placeholder || item.label }
            value={ formData[ item.value ] }
            onChange={(e) =>  onChangeField( e.target.value, item.value ) } />
        )
    }
  }

  return(
    <Modal show={ true } onHide={() => setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{ _.capitalize( mode ) } User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RadioGroup
          row
          value={ formData.role_id }
          name={ 'user-role-id' }
          onChange={ e => onChangeField( Number( e.target.value ), 'role_id' ) }>
          {
            roleOptions.map(( option, index ) => {
              return(
                <FormControlLabel
                  key={ index }
                  value={ option.value }
                  disabled={ formData.created_at }
                  classes={{ label: 'form-label' }}
                  control={ <Radio size="small" /> } label={ option.label } />
              )
            })
          }
        </RadioGroup>
        {
          fieldData.map(( item, index ) => (
            <Form.Group key={ index }>
              <Form.Label>{ item.label }</Form.Label>
              { renderFieldInput( item ) }
            </Form.Group>
          ))
        }
        {
          formData.role_id === 2 && freelanceFieldData.map(( item, index ) => (
            <Form.Group key={ index }>
              <Form.Label>{ item.label }</Form.Label>
              { renderFieldInput( item ) }
            </Form.Group>
          ))
        }
        {
          formData.role_id === 3 && clientFieldData.map(( item, index ) => (
            <Form.Group key={ index }>
              <Form.Label>{ item.label }</Form.Label>
              { renderFieldInput( item ) }
            </Form.Group>
          ))
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          disabled={ _.values( formData ).some( x => x === '' ) }
          variant="primary"
          onClick={ () => onClickSubmit( formData ) } >
          Submit
        </Button>
        {
          ( mode === 'edit' && !selectedUser.isVerified ) && (
            <Button
              variant="secondary"
              onClick={ () => console.log( formData ) } >
              Verify
            </Button>
          )
        }
      </Modal.Footer>
    </Modal>
  )
}

export default UserForm;