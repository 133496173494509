import React, { useEffect } from 'react'
import _ from 'lodash'
import { useOutletContext } from 'react-router-dom';

import CustomInput from "components/Input"
import CustomButton from "components/Button"
import LoadingModal from 'components/LoadingModal'

import { getItem } from 'utils/LocalStorage'
import Logo from 'assets/logo-name.png'
import LoginHOC from './action'
import './index.scss'

const LoginPage = ({
  type,
  onLoginLoad,
  recoverEmail,
  loginCredential,
  showForgetPassword,

  userLogin,
  recoverPassword,
  onChangeLoginHOC
}) => {

  const { onNavigate } = useOutletContext()

  useEffect( () => {
    let temp = type === 'admin'
      ? getItem( 'ADMIN_AUTHORIZATION_TOKEN' )
      : getItem( 'USER_AUTHORIZATION_TOKEN' )

    if( temp !== null ){
      onNavigate( 'statistic' )
    }
  }, [onLoginLoad] )

  const onChangeLogin = ( val, context ) => {
    let tmp = _.cloneDeep( loginCredential )
    tmp[ context ] = val
    onChangeLoginHOC( tmp, "loginCredential" )
  }

  return(
    <div className='at-login__background'>
      <div className='at-login__container'>
        <img src={ Logo } alt={ 'login-logo' } />
        {
          !showForgetPassword && (
            <form>
              <CustomInput
                containerClass={ "mt-2 d-flex justify-content-center" }
                className={ "at-login__input" }
                placeholder={ 'Email' }
                value={ loginCredential.email || '' }
                type={ "text" }
                onChange={ val => onChangeLogin( val, "email" ) }
              />
              <CustomInput
                containerClass={ "mt-2 d-flex justify-content-center" }
                className={ "at-login__input" }
                placeholder={ 'Password' }
                value={ loginCredential.password || '' }
                type={ "password" }
                onChange={ val => onChangeLogin( val, "password" ) }
              />
              <CustomButton
                type={ 'submit' }
                disabled={  _.values( loginCredential ).some( x => !x ) }
                containerClass={"mt-5 d-flex justify-content-center"}
                className={ "btn btn-primary at-login__login-button" }
                content={ 'Login' }
                onClick={ () => userLogin() }
              />
              <CustomButton
                containerClass={ "d-flex justify-content-center" }
                className={ "at-login__forget-password-button" }
                content={ 'Forget Password' }
                onClick={ () => onChangeLoginHOC( true, 'showForgetPassword' ) }
              />
            </form>
          )
        }
        {
          showForgetPassword && (
            <>
              <CustomInput
                containerClass={ "mt-2 d-flex justify-content-center" }
                className={ "at-login__input" }
                placeholder="Email"
                value={ recoverEmail || '' }
                type={ "text" }
                onChange={ val => onChangeLoginHOC( val, 'recoverEmail' ) }
              />
              <CustomButton
                disabled={ !recoverEmail }
                containerClass={"mt-5 d-flex justify-content-center"}
                className={ "btn btn-primary at-login__login-button" }
                content={ "Recover Password" }
                onClick={() => recoverPassword() }
              />
            </>
          )
        }
      </div>
      { onLoginLoad && <LoadingModal /> }
    </div>
  )
}

export default LoginHOC( LoginPage );