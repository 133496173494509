import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie, Line } from 'react-chartjs-2';

import ModuleHeader from 'components/Card/ModuleHeader';

import StatisticHOC from './actions';
import { graphData  } from './dummy';
import { getItem } from 'utils/LocalStorage'

const statisticData = [
  {
    label: 'Graph',
    value: 'graph',
    type: 'bar'
  },
  {
    label: 'Pie',
    value: 'pie',
    type: 'pie'
  },
  {
    label: 'Line Graph',
    value: 'line_graph',
    type: 'line'
  },
  {
    label: 'Line Area Graph',
    value: 'line_area_graph',
    type: 'line'    
  },
  {
    label: 'Bar Graph',
    value: 'bar_graph',
    type: 'bar'
  }
]

ChartJS.register(
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Statistic = ({
  data,
}) => {

  const { onNavigate } = useOutletContext()

  useEffect(() => {
    let temp = getItem( 'ADMIN_AUTHORIZATION_TOKEN' )
    if( temp === null ){
      onNavigate( 'login' )
    }
  }, [])

  return (
    <>
      <ModuleHeader title={ 'Statistic' } />
      <div className='row w-100'>
        {
          statisticData.map(( item, index ) => {
            if( item.type === 'bar' ){
              return(
                <div className='col-md-6 col-xl-4 pr-0 mt-2' key={ index }>
                  <div className='card box-shadow box-center p-4' style={{ minHeight: 480 }}>
                    <Bar data={ graphData[ item.value ] } />
                  </div>
                </div>
              )
            }else if( item.type === 'pie' ){
              return(
                <div className='col-md-6 col-xl-4 pr-0 mt-2' key={ index }>
                  <div className='card box-shadow box-center p-4' style={{ minHeight: 480 }}>
                    <Pie data={ graphData[ item.value ] } />
                  </div>
                </div>
              )
            }else if( item.type === 'line' ){
              return(
                <div className='col-md-6 col-xl-4 pr-0 mt-2' key={ index }>
                  <div className='card box-shadow box-center p-4' style={{ minHeight: 480 }}>
                    <Line data={ graphData[ item.value ] } />
                  </div>
                </div>
              )
            }
          })
        }
      </div>
    </>
  )

}

export default StatisticHOC( Statistic );