import React, { useEffect } from "react";
import { LuUsers, LuEdit3 } from 'react-icons/lu'

import LoadingModal from "components/LoadingModal";
import EmptyState from "components/EmptyState";
import PaginatedTable from "components/PaginatedTable";
import ModuleHeader from 'components/Card/ModuleHeader';
import DeleteConfirmation from 'components/Card/Confirmation'
import CreateForm from './form'
import UpdateForm from './form'

import AnnouncementHOC from './actions';

const columnData = [
  { header: 'Title', accessor: 'title' },
  { header: 'Description', accessor: 'description' },
  { header: 'Type', accessor: 'type' },
  { header: 'Status', containerStyle: { width: '60%' }, renderColumn: rowData => {
    if( rowData.status === 'Active' ){
      return(
        <span className='badge badge-success'>Active</span>
      )
    } else {
      return(
        <span className='badge badge-danger'>{ rowData.status }</span>
      )
    }
  }}
]

const Announcement = ({
  announcements,
  selectedAnnouncement,
  showUpdateModal,
  showDeleteModal,
  showCreateModal,
  onLoadAnnouncement,

  getAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  getSelectedAnnouncement,
  onChangeAnnouncementHOC
}) => {

  useEffect(() => {
    getAnnouncements()
  }, [] )

  return(
    <>
      <ModuleHeader
        title={ 'Announcement / Advertisement' }
        renderIcon={ () => <LuUsers /> }
        description={
          <>
            This module is used to manage announcements and advertisements.
            <ul style={{ paddingLeft: 30 }}>
              <li>Create, Edit, and Termiate announcements.</li>
              <li>View announcement</li>
            </ul>
          </>
        }
        actionButton={[{
          label: 'Create Announcement',
          className: 'btn btn-primary',
          icon: <LuEdit3 />,
          onClick: () => onChangeAnnouncementHOC( true, 'showCreateModal' )
        }]}
      />
      {
        announcements.length > 0 && (
          <PaginatedTable
            columnData={ columnData }
            rowData={ announcements || [] }
            className={ 'mt-2' }
            actionsColumn={[{
              name: 'Edit',
              renderActionIcon: <LuEdit3 />,
              onClick: rowData => getSelectedAnnouncement( rowData.id )
            }, {
              name: 'Delete',
              style: { marginRight: 10 },
              className: 'btn-danger',
              onClick: rowData => Promise.all([
                onChangeAnnouncementHOC( rowData, 'selectedAnnouncement' )
              ]).then( () => {
                onChangeAnnouncementHOC( true, 'showDeleteModal' )
              })
            }]} />
        )
      }
      {
        announcements.length === 0 && <EmptyState title={ 'No Announcements' } />
      }
      {
        showCreateModal && (
          <CreateForm
            mode={ 'create' }
            setOpen={ val => onChangeAnnouncementHOC( val, 'showCreateModal' ) }

            onClickSubmit={ val => createAnnouncement({ ...val, type: 'Announcement' }) } />
        )
      }
      {
        showUpdateModal && (
          <UpdateForm
            mode={ 'update' }
            selectedAnnouncement={ selectedAnnouncement }

            setOpen={ val => onChangeAnnouncementHOC( val, 'showUpdateModal' ) }
            onClickSubmit={ val => updateAnnouncement( val ) } />
        )
      }
      { onLoadAnnouncement && <LoadingModal /> }
      <DeleteConfirmation
        isOpen={ showDeleteModal }
        confirmationText={ `Are you sure you want to delete ${ selectedAnnouncement.title }?` }
        setOpen={ val => onChangeAnnouncementHOC( val, 'showDeleteModal' ) }
        onClick={ () => deleteAnnouncement( selectedAnnouncement.id ) } />
    </>
  )
}

export default AnnouncementHOC( Announcement )
        