export const roleOptions = [
  { label: 'Admin', value: 1 },
  { label: 'Freelancer', value: 2 },
  { label: 'Client', value: 3 },
]

export const education = [
  { label: 'SRP (SIJIL RENDAH PELAJARAN) / PMR (PENILAIAN MENENGAH RENDAH)', value: '1' },
  { label: 'SKM (SIJIL KEMAHIRAN MALAYSIA)', value: '2' },
  { label: 'SPM (SIJIL PELAJARAN MALAYSIA) / IGSE (INTERNATIONAL GENERAL CERTIFICATE OF SECONDARY EDUCATION', value: '3' },
  { label: 'STPM (SIJIL TINGGI PELAJARAN MALAYSIA)', value: '4' },
  { label: 'DIPLOMA / A LEVEL', value: '5' },
  { label: 'BACHELOR DEGREE', value: '6' },
  { label: 'MASTER DEGREE', value: '7' },
  { label: 'PHD', value: '8' },
]

export const license = [
  { label: 'B2', value: 'B2' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'GDL', value: 'GDL' },
]

export const position = [
  { label: 'ACCOUNT MANAGER', value: '1' },
  { label: 'ADMINISTRATION EXECUTIVE', value: '2' },
  { label: 'ADMINISTRATIVE ASSISTANT', value: '3' },
  { label: 'API CRANE INSPECTOR', value: '4' },
  { label: 'ASISTANT FOREMAN', value: '5' },
  { label: 'ASISTANT PROJECT ENGINEER', value: '6' },
  { label: 'ASISTANT SUPERVISOR/FOREMAN', value: '7' },
  { label: 'ASSEMBLY TECHNICIAN', value: '8' },
  { label: 'ASSISTANT ENGINEER', value: '9' },
  { label: 'BLASTER', value: '10' },
  { label: 'BOILER OPERATOR', value: '11' },
  { label: 'BOSUN', value: '12' },
  { label: 'CAMPBOSS', value: '13' },
  { label: 'CATERING SUPERVISOR/FOREMAN', value: '14' },
  { label: 'CHARGEMAN A1', value: '15' },
  { label: 'CHARGEMAN A4', value: '16' },
  { label: 'CHARGEMAN A4-1', value: '17' },
  { label: 'CHARGEMAN A4-2', value: '18' },
  { label: 'CHARGEMAN AO', value: '19' },
  { label: 'CHARGEMAN B1', value: '20' },
  { label: 'CHARGEMAN B4', value: '21' },
  { label: 'CHARGEMAN BO', value: '22' },
  { label: 'CHARGEMAN BO-1', value: '23' },
  { label: 'CHARGEMAN BO-2', value: '24' },
  { label: 'CHEF', value: '25' },
  { label: 'CHEMICAL ENGINEER', value: '26' },
  { label: 'CHEMIST/CHEMICAL ENGINEER', value: '27' },
  { label: 'CHIEF 1ST ENGINEER', value: '28' },
  { label: 'CHIEF 1ST OFFICER', value: '29' },
  { label: 'CHIEF 2ND ENGINEER', value: '30' },
  { label: 'CHIEF 2ND OFFICER ', value: '31' },
  { label: 'CHIEF 3RD ENGINEER', value: '32' },
  { label: 'CIVIL ENGINEER', value: '33' },
  { label: 'CLERK', value: '34' },
  { label: 'CNC MACHINIST', value: '35' },
  { label: 'COMMISSIONING ENGINEER', value: '36' },
  { label: 'COMMISSIONING TECHNICIAN', value: '37' },
  { label: 'COMPLETION ENGINEER', value: '38' },
  { label: 'CONTRACT SPECIALIST', value: '39' },
  { label: 'CONTROL ROOM OPERATOR', value: '40' },
  { label: 'COOKS', value: '41' },
  { label: 'CRANE OPERATOR', value: '42' },
  { label: 'CRANE TECHNICIAN ', value: '43' },
  { label: 'DIESEL ENGINE TECHNICIAN', value: '44' },
  { label: 'DRILLING ENGINEER', value: '45' },
  { label: 'DRILLING SAFETY SUPERVISOR/FOREMAN', value: '46' },
  { label: 'DRIVER', value: '47' },
  { label: 'E&I ENGINEER', value: '48' },
  { label: 'E&I TTECHNICIAN', value: '49' },
  { label: 'ELECTRICAL ENGINEER', value: '50' },
  { label: 'ELECTRICAL TECHNICIAN', value: '51' },
  { label: 'ELECTRICIAN', value: '52' },
  { label: 'ELECTRONIC ENGINEER', value: '53' },
  { label: 'ENVIRONMENTAL ENGINEER', value: '54' },
  { label: 'EQUIPMENT OPERATOR', value: '55' },
  { label: 'EQUIPMENT TRAINER', value: '56' },
  { label: 'FIELD OPERATOR', value: '57' },
  { label: 'FIELD SERVICE TECHNICIAN', value: '58' },
  { label: 'FIREWATCHER', value: '59' },
  { label: 'FOREMAN', value: '60' },
  { label: 'GENERAL FITTER', value: '61' },
  { label: 'GENERAL INSPECTOR', value: '62' },
  { label: 'GEOLOGIST', value: '63' },
  { label: 'HABITAT INSPECTOR', value: '64' },
  { label: 'HABITAT TECHNICIAN', value: '65' },
  { label: 'HEAD CHEF', value: '66' },
  { label: 'HEALTH AND SAFETY SPECIALIST', value: '67' },
  { label: 'HEALTH, SAFETY AND ENVIRONMENT (HSE) TRAINER', value: '68' },
  { label: 'HEALTH, SAFETY, AND ENVIRONMENT (HSE) OFFICER', value: '69' },
  { label: 'HELPER', value: '70' },
  { label: 'HSE MANAGER', value: '71' },
  { label: 'HUMAN RESOURCES MANAGER', value: '72' },
  { label: 'HVAC ENGINEER', value: '73' },
  { label: 'HVEC SUPERVISOR', value: '74' },
  { label: 'HYDRAULIC TECHNICIAN', value: '75' },
  { label: 'INSTRUMENT FITTER', value: '76' },
  { label: 'INSTRUMENT FOREMAN', value: '77' },
  { label: 'INSTRUMENT TECHINCIAN', value: '78' },
  { label: 'INSTRUMENTATION AND CONTROL ENGINEER', value: '79' },
  { label: 'INSTRUMENTATION AND CONTROL TECHNICIAN', value: '80' },
  { label: 'INTERGRITY ENGINEER', value: '81' },
  { label: 'IT ENGINEER', value: '82' },
  { label: 'IT TECHNICIAN ', value: '83' },
  { label: 'LEADERSHIP TRAINER', value: '84' },
  { label: 'LEGAL COUNSEL', value: '85' },
  { label: 'LIFTING ENGINEER', value: '86' },
  { label: 'MACHINIST', value: '87' },
  { label: 'MAINTENANCE ENGINEER', value: '88' },
  { label: 'MAINTENANCE MANAGER', value: '89' },
  { label: 'MAINTENANCE SUPERVISOR/FOREMAN', value: '90' },
  { label: 'MARINE EXECUTIVE', value: '91' },
  { label: 'MARINE OPERATIONS SUPERVISOR/FOREMAN', value: '92' },
  { label: 'MATERIAL EXPEDITING', value: '93' },
  { label: 'MATERIALS HANDLER/LOGISTICS COORDINATOR', value: '94' },
  { label: 'MECHANICAL ENGINEER', value: '95' },
  { label: 'MECHANICAL TECHNICIAN', value: '96' },
  { label: 'MOTORMAN', value: '97' },
  { label: 'NDT INSPECTOR (EDDY CURRENT) LEVEL II', value: '98' },
  { label: 'NDT INSPECTOR (MPI) LEVEL II', value: '99' },
  { label: 'NDT INSPECTOR (PT) LEVEL II', value: '100' },
  { label: 'NDT INSPECTOR (RI) LEVEL II', value: '101' },
  { label: 'NDT INSPECTOR (RT)', value: '102' },
  { label: 'NDT INSPECTOR (UT) LEVEL II', value: '103' },
  { label: 'OFFSHORE CATERING STAFF', value: '104' },
  { label: 'OFFSHORE CONSTRUCTION MANAGER', value: '105' },
  { label: 'OFFSHORE CRANE OPERATOR LEVEL 1', value: '106' },
  { label: 'OFFSHORE CRANE OPERATOR LEVEL 2', value: '107' },
  { label: 'OFFSHORE CRANE OPERATOR LEVEL 3', value: '108' },
  { label: 'OFFSHORE DIVER', value: '109' },
  { label: 'OFFSHORE DRILLING SUPERVISOR/FOREMAN', value: '110' },
  { label: 'OFFSHORE ELECTRICIAN', value: '111' },
  { label: 'OFFSHORE ENGINEER', value: '112' },
  { label: 'OFFSHORE GEOLOGIST', value: '113' },
  { label: 'OFFSHORE INSTALLATION MANAGER', value: '114' },
  { label: 'OFFSHORE LOGISTICS COORDINATOR', value: '115' },
  { label: 'OFFSHORE MAINTENANCE TECHNICIAN', value: '116' },
  { label: 'OFFSHORE MECHANIC', value: '117' },
  { label: 'OFFSHORE MEDIC', value: '118' },
  { label: 'OFFSHORE PRODUCTION OPERATOR', value: '119' },
  { label: 'OFFSHORE PROJECT ENGINEER', value: '120' },
  { label: 'OFFSHORE RIG MANAGER', value: '121' },
  { label: 'OFFSHORE ROV (REMOTELY OPERATED VEHICLE) PILOT', value: '122' },
  { label: 'OFFSHORE SAFETY OFFICER', value: '123' },
  { label: 'OFFSHORE SURVEYOR', value: '124' },
  { label: 'OFFSHORE WELDER', value: '125' },
  { label: 'ONBOARDING TRAINER', value: '126' },
  { label: 'OPERATION ENGINEER', value: '127' },
  { label: 'OPERATION TECHNICIAN', value: '128' },
  { label: 'OPERATIONS MANAGER', value: '129' },
  { label: 'PAINTER LEVEL I', value: '130' },
  { label: 'PAINTER LEVEL II', value: '131' },
  { label: 'PAINTING INSPECTOR LEVEL I', value: '132' },
  { label: 'PAINTING INSPECTOR LEVEL II', value: '133' },
  { label: 'PAUT INSPECTOR LEVEL I', value: '134' },
  { label: 'PAUT INSPECTOR LEVEL II', value: '135' },
  { label: 'PETROLEUM ENGINEER', value: '136' },
  { label: 'PIPELINE ENGINEER', value: '137' },
  { label: 'PIPELINE FITTER', value: '138' },
  { label: 'PIPELINE INSPECTOR', value: '139' },
  { label: 'PIPING FITTER', value: '140' },
  { label: 'PIPING INSPECTOR', value: '141' },
  { label: 'PLANNER / SCHEDULER', value: '142' },
  { label: 'PLANT INSPECTOR', value: '143' },
  { label: 'PLANT MANAGER', value: '144' },
  { label: 'PROCESS ENGINEER', value: '145' },
  { label: 'PROCESS TRAINER', value: '146' },
  { label: 'PROCUREMENT SPECIALIST', value: '147' },
  { label: 'PRODUCTION ENGINEER', value: '148' },
  { label: 'PRODUCTION SUPERVISOR/FOREMAN', value: '149' },
  { label: 'PROJECT ENGINEER', value: '150' },
  { label: 'PROJECT MANAGER', value: '151' },
  { label: 'PROJECT SUPERVISOR/FOREMAN', value: '152' },
  { label: 'PUMP OPERATOR', value: '153' },
  { label: 'QA ENGINEER', value: '154' },
  { label: 'QAQC ENGINEER', value: '155' },
  { label: 'QC ENGINEER', value: '156' },
  { label: 'RESERVOIR ENGINEER', value: '157' },
  { label: 'RIGGER LEVEL I', value: '158' },
  { label: 'RIGGER LEVEL II', value: '159' },
  { label: 'RIGGER SCAFFOLDER LEVEL II', value: '160' },
  { label: 'RIGGER SUPERVISOR/FOREMAN', value: '161' },
  { label: 'ROPE ACCESS INSPECTOR L1', value: '162' },
  { label: 'ROTATING ENGINEER', value: '163' },
  { label: 'SAFETY OFFICER', value: '164' },
  { label: 'SAFETY OFFICER (YELLOW) ETC', value: '165' },
  { label: 'SALES ENGINEER', value: '166' },
  { label: 'SCAFFOLDER LEVEL II', value: '167' },
  { label: 'SCAFFOLDING GENERAL SUPERVISOR LEVEL III', value: '168' },
  { label: 'SCAFFOLDING INSPECTOR LEVEL III', value: '169' },
  { label: 'SERVICE ENGINEER', value: '170' },
  { label: 'SITE / FIELD ENGINEER', value: '171' },
  { label: 'SITE OPERATION MANAGER / SERVICE OPERATION MANAGER', value: '172' },
  { label: 'SITE/AREA SUPERVISOR/FOREMAN', value: '173' },
  { label: 'STRUCTURAL FITTER', value: '174' },
  { label: 'SUPERVISOR/FOREMAN', value: '175' },
  { label: 'SUPPLY CHAIN MANAGER', value: '176' },
  { label: 'SUPPLY CHAIN MANAGER', value: '177' },
  { label: 'TEAM LEADER /WORK LEADER /GENERAL SUPERVISOR', value: '178' },
  { label: 'TEAM SMITH', value: '179' },
  { label: 'TECHNICAL ASSISTANT', value: '180' },
  { label: 'TECHNICAL SALES REPRESENTATIVE', value: '181' },
  { label: 'TECHNICAL TRAINER', value: '182' },
  { label: 'TECHNOLOGY TRAINER', value: '183' },
  { label: 'TOFD INSPECTOR LEVEL I', value: '184' },
  { label: 'TOFD INSPECTOR LEVEL II', value: '185' },
  { label: 'TRAINING AND DEVELOPMENT SPECIALIST', value: '186' },
  { label: 'TURBINE OPERATOR', value: '187' },
  { label: 'VIBRATION ANALYST', value: '188' },
  { label: 'WELDER (FCAW)', value: '189' },
  { label: 'WELDER (GTAW)', value: '190' },
  { label: 'WELDER (GTAW/SMAW)', value: '191' },
  { label: 'WELDER (SAW)', value: '192' },
  { label: 'WELDER (SAW/FCAW)', value: '193' },
  { label: 'WELDER (SMAW)', value: '194' },
  { label: 'WELDER (SMAW/FCAW)', value: '195' },
  { label: 'WELL SERVICE ENGINEER', value: '196' },
  { label: 'WELL TEST ENGINEER', value: '197' },
]

export const certification = [
  { label: 'SRP/PMR/SPM/STPM/SIJIL KEMAHIRAN/DIPLOMA/BACHELOR DEGREE/MASTER DEGREE/PHD', value: '1' },
  { label: 'CV', value: '2' },
  { label: 'INTERNATIONAL WELL CONTROL FORUM  (IWCF)', value: '3' },
  { label: 'RESCUE AT HEIGHT - LEVEL 1', value: '4' },
  { label: 'A1 CERTIFICATE', value: '5' },
  { label: 'A4 CERTIFICATE', value: '6' },
  { label: 'A4-1 CERTIFICATE', value: '7' },
  { label: 'A4-2 CERTIFICATE', value: '8' },
  { label: 'ADVANCED RIGGING & SLINGING / BANKSMAN', value: '9' },
  { label: 'AO CERTIFICATE', value: '10' },
  { label: 'API 1169 - PIPELINE CONSTRUCTION INSPECTOR CERTIFICATION', value: '11' },
  { label: 'API 510 - PRESSURE VESSEL INSPECTOR CERTIFICATION', value: '12' },
  { label: 'API 570 - PIPING INSPECTOR CERTIFICATION', value: '13' },
  { label: 'API 577 WELDING INSPECTION AND METALLUARGY', value: '14' },
  { label: 'API 580 - RISK BASED INSPECTION CERTIFICATION', value: '15' },
  { label: 'API 653 - ABOVEGROUND STORAGE TANK INSPECTOR CERTIFICATION', value: '16' },
  { label: 'API Q1 - QUALITY MANAGEMENT SYSTEM CERTIFICATION FOR MANUFACTURERS', value: '17' },
  { label: 'API Q2 - QUALITY MANAGEMENT SYSTEM CERTIFICATION FOR SERVICE SUPPLY ORGANIZATIONS', value: '18' },
  { label: 'API RP 2D BANKSMAN', value: '19' },
  { label: 'API RP 2D HYDRAULIC PEDESTAL CRANE SAFETY TRAINING', value: '20' },
  { label: 'API RP 2D RIGGER SAFETY TRAINING', value: '21' },
  { label: 'API SPEC 16A - SPECIFICATION FOR DRILL-THROUGH EQUIPMENT', value: '22' },
  { label: 'API SPEC 16C - SPECIFICATION FOR CHOKE AND KILL SYSTEMS', value: '23' },
  { label: 'API SPEC 17D - SPECIFICATION FOR SUBSEA WELLHEAD AND TREE EQUIPMENT', value: '24' },
  { label: 'API SPEC 17E - SPECIFICATION FOR SUBSEA UMBILICALS', value: '25' },
  { label: 'API SPEC 19AC - SPECIFICATION FOR QUALITY MANAGEMENT SYSTEM REQUIREMENTS FOR DOWNHOLE EQUIPMENT FOR THE PETROLEUM AND NATURAL GAS INDUSTRIES', value: '26' },
  { label: 'API SPEC 19G1 - SPECIFICATION FOR EVALUATION OF PIPELINE AND PRESSURE VESSEL THREADED FASTENERS', value: '27' },
  { label: 'API SPEC 5L - LINE PIPE SPECIFICATION', value: '28' },
  { label: 'API SPEC 7-1 - SPECIFICATION FOR ROTARY DRILL STEM ELEMENTS', value: '29' },
  { label: 'API SPEC 7K - SPECIFICATION FOR DRILLING AND WELL SERVICING EQUIPMENT', value: '30' },
  { label: 'API SPEC Q1 - SPECIFICATION FOR QUALITY MANAGEMENT SYSTEM REQUIREMENTS FOR MANUFACTURING ORGANIZATIONS FOR THE PETROLEUM AND NATURAL GAS INDUSTRY', value: '31' },
  { label: 'ASME NDT ACCP PROFESSIONAL LEVEL III', value: '32' },
  { label: 'ASME NDT ACCP SENIOR LEVEL III', value: '33' },
  { label: 'ASME NDT LEVEL I', value: '34' },
  { label: 'ASME NDT LEVEL II', value: '35' },
  { label: 'ASME NDT LEVEL III', value: '36' },
  { label: 'ASNT LEVEL I', value: '37' },
  { label: 'ASNT LEVEL II', value: '38' },
  { label: 'ASNT LEVEL III', value: '39' },
  { label: 'AUTHORISED GAS TESTER', value: '40' },
  { label: 'AWS CERTIFIED WELDER - FLUX CORED ARC WELDING (FCAW) - FLAT, HORIZONTAL, VERTICAL AND OVERHEAD POSITIONS', value: '41' },
  { label: 'AWS CERTIFIED WELDER - GAS METAL ARC WELDING (GMAW) - FLAT, HORIZONTAL, VERTICAL AND OVERHEAD POSITIONS', value: '42' },
  { label: 'AWS CERTIFIED WELDER - GAS TUNGSTEN ARC WELDING (GTAW) - FLAT, HORIZONTAL, VERTICAL AND OVERHEAD POSITIONS', value: '43' },
  { label: 'AWS CERTIFIED WELDER - SHIELDED METAL ARC WELDING (SMAW) - FLAT, HORIZONTAL, VERTICAL AND OVERHEAD POSITIONS', value: '44' },
  { label: 'AWS CERTIFIED WELDER - SUBMERGED ARC WELDING (SAW) - FLAT AND HORIZONTAL POSITIONS', value: '45' },
  { label: 'B1 CERTIFICATE', value: '46' },
  { label: 'B4 CERTIFICATE', value: '47' },
  { label: 'BASIC FIRE WATCH (BFW)', value: '48' },
  { label: 'BASIC H2S TRAINING  (OPITO APPROVED)', value: '49' },
  { label: 'BASIC HYDROGEN SULPHIDE', value: '50' },
  { label: 'BASIC MERCURY AWARENESS & HAZMAT HANDLING', value: '51' },
  { label: 'BASIC RIGGING AND SLINGING', value: '52' },
  { label: 'BASIC RIGGING AND SLINGING / BANKSMAN (EM)', value: '53' },
  { label: 'BGAS CSWIP LEVEL I', value: '54' },
  { label: 'BGAS CSWIP LEVEL II', value: '55' },
  { label: 'BO CERTIFICATE', value: '56' },
  { label: 'BO-1 CERTIFICATE', value: '57' },
  { label: 'BO-2 CERTIFICATE', value: '58' },
  { label: 'BOSEIT', value: '59' },
  { label: 'CA-EBS', value: '60' },
  { label: 'CERTIFIED VIBRATION PRACTITIONER CATEGORY 1', value: '61' },
  { label: 'CERTIFIED WELDING ENGINEER (CWE)', value: '62' },
  { label: 'CIA - CERTIFIED INTERNAL AUDITOR', value: '63' },
  { label: 'CONFINED SPACE', value: '64' },
  { label: 'CONFINED SPACE SAFETY WITH BREATHING APPARATUS ', value: '65' },
  { label: 'COXSWAIN WITH MAN OVERBOARD ', value: '66' },
  { label: 'CRANE SIGNALMAN', value: '67' },
  { label: 'CRASHED PILOT & AIR CREW SURVIVAL', value: '68' },
  { label: 'CSWIP 3.0 VISUAL WELDING INSPECTOR', value: '69' },
  { label: 'CSWIP 3.1 WELDING INSPECTOR', value: '70' },
  { label: 'CSWIP 3.2 SENIOR WELDING INSPECTOR', value: '71' },
  { label: 'CSWIP 3.3 WELDING INSPECTOR (UNDERWATER)', value: '72' },
  { label: 'CSWIP 3.4 PLANT INSPECTION', value: '73' },
  { label: 'CSWIP 3.5 RADIOGRAPHIC INTERPRETATION', value: '74' },
  { label: 'CSWIP 3.6 VISUAL WELDING INSPECTOR - LEVEL 2', value: '75' },
  { label: 'CSWIP 3.7 WELDING QUALITY CONTROL COORDINATOR', value: '76' },
  { label: 'CSWIP 3.8 SENIOR WELDING INSPECTOR (BRIDGES)', value: '77' },
  { label: 'CSWIP 3.9 WELDING SUPERVISOR/FOREMAN', value: '78' },
  { label: 'CSWIP NDT DYE PENETRANT TESTING (PT) OPERATOR', value: '79' },
  { label: 'CSWIP NDT LEVEL 1', value: '80' },
  { label: 'CSWIP NDT LEVEL 2', value: '81' },
  { label: 'CSWIP NDT MAGNETIC PARTICLE TESTING (MT) OPERATOR', value: '83' },
  { label: 'CSWIP NDT PLANT INSPECTOR', value: '84' },
  { label: 'CSWIP NDT SENIOR PLANT INSPECTOR', value: '85' },
  { label: 'CSWIP NDT SENIOR WELD INSPECTOR', value: '86' },
  { label: 'CSWIP NDT ULTRASONIC TESTING (UT) OPERATOR', value: '87' },
  { label: 'CSWIP NDT WELD INSPECTOR', value: '88' },
  { label: 'CWI - CERTIFIED WELDING INSPECTOR', value: '89' },
  { label: 'CWI/CWE - CERTIFIED WELDING INSPECTOR/CERTIFIED WELDING EDUCATOR', value: '90' },
  { label: 'DAMAGE CONTROL TEAM', value: '91' },
  { label: 'DAMAGE CONTROL TEAM WITH HELICOPTER FAMILIARIZATION ', value: '92' },
  { label: 'DAMAGE CONTROL TEAM WITH HELICRASH ', value: '93' },
  { label: 'EMERGENCY RESPONSE TEAM', value: '94' },
  { label: 'EXXONMOBIL LOSS PREVENTION SYSTEM (LPS)', value: '95' },
  { label: 'EXXONMOBIL MEDICAL CERTIFICATE', value: '96' },
  { label: 'FIRE AND RESCUE BOAT HANDLING', value: '97' },
  { label: 'FIRE EMERGENCY RESPONSE TEAM MEMBER (ERT)', value: '98' },
  { label: 'FIRE FIGHTING LEVEL 1', value: '99' },
  { label: 'FIRE FIGHTING LEVEL 2', value: '100' },
  { label: 'FIRE FIGHTING LEVEL 3', value: '101' },
  { label: 'H2S', value: '102' },
  { label: 'HAZARD ANALYSIS CRITICAL CONTROL POINT (HACCP)', value: '103' },
  { label: 'HELICOPTER DECK ASSISTANT (HDA) ', value: '104' },
  { label: 'HELICOPTER FIRE AND CRASH PROFESSIONAL RESCUE TRAINING (PUTD)', value: '105' },
  { label: 'HELICOPTER FIRE FIGHTING (NOGEPA 2.9)', value: '106' },
  { label: 'HELICOPTER LANDING OFFICER (HLO)', value: '107' },
  { label: 'HELICOPTER UNDERWATER ESCAPE TRAINING (HUET) WITH EMERGENCY BREATHING SYSTEM (EBS)', value: '108' },
  { label: 'HELIDECK EMERGENCY RESPONSE TEAM (HERTM)', value: '109' },
  { label: 'HELIDECK RADIO OPERATOR (HRO) ', value: '110' },
  { label: 'HYDROGEN SULPHIDE AWARENESS', value: '111' },
  { label: 'HYDROGEN SULPHIDE COMPETENCE', value: '112' },
  { label: 'HYDROGEN SULPHIDE SAFETY ', value: '113' },
  { label: 'HYDROGEN SULPHIDE, MERCURY AND BENZENE AWARENESS', value: '114' },
  { label: 'IADC WELLSHARP DRILLER LEVEL WELL CONTROL', value: '115' },
  { label: 'IADC WELLSHARP INTRODUCTORY LEVEL WELL CONTROL', value: '116' },
  { label: 'IADC WELLSHARP SUPERVISORY LEVEL WELL CONTROL', value: '117' },
  { label: 'IECEx CoPC EX 000', value: '118' },
  { label: 'IECEx CoPC EX 001', value: '119' },
  { label: 'IECEx CoPC EX 002', value: '120' },
  { label: 'IECEx CoPC EX 003', value: '121' },
  { label: 'IECEx CoPC EX 004', value: '122' },
  { label: 'IECEx CoPC EX 005', value: '123' },
  { label: 'IECEx CoPC EX 006', value: '124' },
  { label: 'IECEx CoPC EX 007', value: '125' },
  { label: 'IECEx CoPC EX 008', value: '126' },
  { label: 'IECEx CoPC EX 009', value: '127' },
  { label: 'IECEx CoPC EX 010', value: '128' },
  { label: 'IMM CERTIFIED ASSISTANT BLASTER AND PAINTER B1B2', value: '129' },
  { label: 'IMM CERTIFIED ASSOCIATE WELDING ENGINEER', value: '130' },
  { label: 'IMM CERTIFIED BLASTING AND PAINTING SUPERVISOR/FOREMAN', value: '131' },
  { label: 'IMM CERTIFIED CATHODIC PROTECTION ENGINEER', value: '132' },
  { label: 'IMM CERTIFIED CATHODIC PROTECTION TECHNICIAN LEVEL 1', value: '133' },
  { label: 'IMM CERTIFIED CATHODIC PROTECTION TECHNICIAN LEVEL 2', value: '134' },
  { label: 'IMM CERTIFIED COATING FINGERPRINT QUALITY CONTROLLER LEVEL 1', value: '135' },
  { label: 'IMM CERTIFIED COATING FINGERPRINT QUALITY CONTROLLER LEVEL 2', value: '136' },
  { label: 'IMM CERTIFIED COATING FINGERPRINT TRAINER', value: '137' },
  { label: 'IMM CERTIFIED COATING INSPECTOR LEVEL 1', value: '138' },
  { label: 'IMM CERTIFIED COATING INSPECTOR LEVEL 2', value: '139' },
  { label: 'IMM CERTIFIED COATING QUALITY CONTROL TECHNICIAN', value: '140' },
  { label: 'IMM CERTIFIED CORROSION TECHNICIAN LEVEL 1', value: '141' },
  { label: 'IMM CERTIFIED CORROSION TECHNICIAN LEVEL 2', value: '142' },
  { label: 'IMM CERTIFIED MECHANICAL JOINT INTEGRITY FOR FLANGE BOLTED CONNECTIONS', value: '143' },
  { label: 'IMM CERTIFIED MECHANICAL JOINT INTEGRITY FOR SMALL-BORE PIPING, TUBING AND VALVES', value: '144' },
  { label: 'IMM CERTIFIED PROTECTIVE COATING TECHNICIAN (BLASTER AND/OR PAINTER) LEVEL 1 AND LEVEL 2', value: '145' },
  { label: 'IMM CERTIFIED SENIOR WELDING ENGINEER', value: '146' },
  { label: 'IMM CERTIFIED THERMAL INSULATION INSTALLER', value: '147' },
  { label: 'IMM CERTIFIED THERMAL SPRAY COATING APPLICATOR', value: '148' },
  { label: 'IMM CERTIFIED THERMIT WELDING PRACTITIONER (LEVEL 1)', value: '149' },
  { label: 'IMM CERTIFIED THERMIT WELDING SENIOR PRACTITIONER (LEVEL 2)', value: '150' },
  { label: 'IMM CERTIFIED TRAINER', value: '151' },
  { label: 'IMM CERTIFIED VIBRATION PRACTITIONER CATEGORY 1', value: '152' },
  { label: 'IMM CERTIFIED VIBRATION PRACTITIONER CATEGORY 2', value: '153' },
  { label: 'IMM CERTIFIED VIBRATION SPECIALIST CATEGORY 3', value: '154' },
  { label: 'IMM CERTIFIED VIBRATION SPECIALIST CATEGORY 4', value: '155' },
  { label: 'IMM CERTIFIED WELDING ENGINEER', value: '156' },
  { label: 'IMM CERTIFIED WELDING INSPECTOR', value: '157' },
  { label: 'IRATA ROPE ACCESS LEVEL 1', value: '158' },
  { label: 'IRATA ROPE ACCESS LEVEL 2', value: '159' },
  { label: 'IRATA ROPE ACCESS LEVEL 3', value: '160' },
  { label: 'ISO 10002 - CUSTOMER SATISFACTION - GUIDELINES FOR COMPLAINTS HANDLING IN ORGANIZATIONS', value: '161' },
  { label: 'ISO 10675-1:2017 - NON-DESTRUCTIVE TESTING OF WELDS - PART 1: MAGNETIC PARTICLE TESTING', value: '162' },
  { label: 'ISO 10002 - CUSTOMER SATISFACTION - GUIDELINES FOR COMPLAINTS HANDLING IN ORGANIZATIONS', value: '163' },
  { label: 'ISO 10675-1:2017 - NON-DESTRUCTIVE TESTING OF WELDS -- ACCEPTANCE LEVELS FOR RADIOGRAPHIC TESTING -- PART 1: STEEL, NICKEL, TITANIUM, AND THEIR ALLOYS', value: '164' },
  { label: 'ISO 10863:2011 - NON-DESTRUCTIVE TESTING OF WELDS -- ULTRASONIC TESTING -- CHARACTERIZATION OF DISCONTINUITIES IN WELDS', value: '165' },
  { label: 'ISO 14001 - ENVIRONMENTAL MANAGEMENT SYSTEM', value: '166' },
  { label: 'ISO 15626:2010 - NON-DESTRUCTIVE TESTING OF WELDS -- ULTRASONIC TESTING -- TECHNIQUES, TESTING LEVELS, AND ASSESSMENT', value: '167' },
  { label: 'ISO 17636-1:2013 - NON-DESTRUCTIVE TESTING OF WELDS -- RADIOGRAPHIC TESTING -- PART 1: X- AND GAMMA-RAY TECHNIQUES WITH FILM', value: '168' },
  { label: 'ISO 19232-2:2017 - NON-DESTRUCTIVE TESTING OF WELDS -- IMAGE QUALITY OF RADIOGRAPHS -- PART 2: DETERMINATION OF THE IMAGE QUALITY VALUE USING WIRE-TYPE IMAGE QUALITY INDICATORS', value: '169' },
  { label: 'ISO 19600 - COMPLIANCE MANAGEMENT SYSTEM', value: '170' },
  { label: 'ISO 20000 - IT SERVICE MANAGEMENT SYSTEM', value: '171' },
  { label: 'ISO 22301 - BUSINESS CONTINUITY MANAGEMENT SYSTEM', value: '172' },
  { label: 'ISO 22825:2017 - NON-DESTRUCTIVE TESTING OF WELDS -- ULTRASONIC TESTING -- TESTING OF WELDS IN AUSTENITIC STEELS AND NICKEL-BASE ALLOYS', value: '173' },
  { label: 'ISO 27001 - INFORMATION SECURITY MANAGEMENT SYSTEM', value: '174' },
  { label: 'ISO 31000 - RISK MANAGEMENT', value: '175' },
  { label: 'ISO 3452-2:2013 - NON-DESTRUCTIVE TESTING- PENETRANT TESTING - PART 2: TESTING OF PENETRANT MATERIALS', value: '176' },
  { label: 'ISO 45001 - OCCUPATIONAL HEALTH AND SAFETY MANAGEMENT SYSTEM', value: '177' },
  { label: 'ISO 50001 - ENERGY MANAGEMENT SYSTEM', value: '178' },
  { label: 'ISO 9001 - QUALITY MANAGEMENT SYSTEM', value: '179' },
  { label: 'ISO 9712:2012 - NON-DESTRUCTIVE TESTING - QUALIFICATION AND CERTIFICATION OF PERSONNEL', value: '180' },
  { label: 'ISO/IEC 17025 - TESTING AND CALIBRATION LABORATORIES', value: '181' },
  { label: 'IWCF DRILLER LEVEL 3 WELL CONTROL', value: '182' },
  { label: 'IWCF INTRO LEVEL 2 WELL INTERVENTION PRESSURE CONTROL', value: '183' },
  { label: 'IWCF LEVEL 2 (INTRODUCTORY) WELL CONTROL', value: '184' },
  { label: 'IWCF OPERATOR LEVEL 3 WELL INTERVENTION PRESSURE CONTROL', value: '185' },
  { label: 'IWCF SUPERVISOR LEVEL 4 WELL CONTROL', value: '186' },
  { label: 'IWCF SUPERVISOR LEVEL 4 WELL INTERVENTION PRESSURE CONTROL', value: '187' },
  { label: 'NACE LEVEL 1: CATHODIC PROTECTION TESTER', value: '188' },
  { label: 'NACE LEVEL 1: CERTIFIED COATING INSPECTOR', value: '189' },
  { label: 'NACE LEVEL 1: CERTIFIED CORROSION TECHNICIAN', value: '190' },
  { label: 'NACE LEVEL 1: CERTIFIED PIPELINE CORROSION INTEGRITY MANAGEMENT TECHNICIAN', value: '191' },
  { label: 'NACE LEVEL 2: CATHODIC PROTECTION TECHNICIAN', value: '192' },
  { label: 'NACE LEVEL 2: CERTIFIED CORROSION TECHNOLOGIST ', value: '193' },
  { label: 'NACE LEVEL 2: CERTIFIED PIPELINE CORROSION INTEGRITY MANAGEMENT TECHNOLOGIST', value: '194' },
  { label: 'NACE LEVEL 2: CERTIFIED SENIOR COATING INSPECTOR', value: '195' },
  { label: 'NACE LEVEL 3: CATHODIC PROTECTION TECHNOLOGIST', value: '196' },
  { label: 'NATIONAL COMMISSION FOR THE CERTIFICATION OF CRANE OPERATORS (NCCCO)', value: '197' },
  { label: 'NIOSH', value: '198' },
  { label: 'OFFSHORE CRANE OPERATOR LEVEL 1', value: '199' },
  { label: 'OFFSHORE CRANE OPERATOR LEVEL 2', value: '200' },
  { label: 'OFFSHORE CRANE OPERATOR LEVEL 3', value: '201' },
  { label: 'OFFSHORE EMERGENCY RESPONSE TEAM LEADER (OERTL)', value: '202' },
  { label: 'OIL AND GAS SAFETY PASSPORT LEVEL 1', value: '203' },
  { label: 'OIL AND GAS SAFETY PASSPORT LEVEL 2', value: '204' },
  { label: 'OIL POLLUTION PREPAREDNESS, RESPONSE AND CO-OPERATION LEVEL 1', value: '205' },
  { label: 'OIL POLLUTION PREPAREDNESS, RESPONSE AND CO-OPERATION LEVEL 2', value: '206' },
  { label: 'OIL POLLUTION PREPAREDNESS, RESPONSE AND CO-OPERATION LEVEL 3', value: '207' },
  { label: 'OIM CONTROLLING EMERGENCIES', value: '208' },
  { label: 'PARACHUTE LANDING OVER WATER AND SEA SURVIVAL (PLOWSS)', value: '209' },
  { label: 'PCN LEVEL 1 EDDY CURRENT TESTING (ET)', value: '210' },
  { label: 'PCN LEVEL 1 LIQUID PENETRANT TESTING (PT)', value: '211' },
  { label: 'PCN LEVEL 1 MAGNETIC PARTICLE TESTING (MT)', value: '212' },
  { label: 'PCN LEVEL 1 RADIOGRAPHIC TESTING (RT)', value: '213' },
  { label: 'PCN LEVEL 1 ULTRASONIC TESTING (UT)', value: '214' },
  { label: 'PCN LEVEL 1 VISUAL TESTING (VT)', value: '215' },
  { label: 'PCN LEVEL 2 EDDY CURRENT TESTING (ET)', value: '216' },
  { label: 'PCN LEVEL 2 LIQUID PENETRANT TESTING (PT)', value: '217' },
  { label: 'PCN LEVEL 2 MAGNETIC PARTICLE TESTING (MT)', value: '218' },
  { label: 'PCN LEVEL 2 RADIOGRAPHIC TESTING (RT)', value: '219' },
  { label: 'PCN LEVEL 2 ULTRASONIC TESTING (UT)', value: '220' },
  { label: 'PCN LEVEL 2 VISUAL TESTING (VT)', value: '221' },
  { label: 'PCN LEVEL 3 EDDY CURRENT TESTING (ET)', value: '222' },
  { label: 'PCN LEVEL 3 LIQUID PENETRANT TESTING (PT)', value: '223' },
  { label: 'PCN LEVEL 3 MAGNETIC PARTICLE TESTING (MT)', value: '224' },
  { label: 'PCN LEVEL 3 RADIOGRAPHIC TESTING (RT)', value: '225' },
  { label: 'PCN LEVEL 3 ULTRASONIC TESTING (UT)', value: '226' },
  { label: 'PCN LEVEL 3 VISUAL TESTING (VT)', value: '227' },
  { label: 'PETRONAS MEDICAL CERTIFICATES', value: '228' },
  { label: 'PETRONAS PTW LEVEL I', value: '229' },
  { label: 'PETRONAS PTW LEVEL II', value: '230' },
  { label: 'PETRONAS PTW LEVEL III', value: '231' },
  { label: 'RESCUE AT HEIGHT –  LEVEL 2 ', value: '232' },
  { label: 'SARAWAK WORK PERMIT', value: '233' },
  { label: 'SCWI - SENIOR CERTIFIED WELDING INSPECTOR', value: '234' },
  { label: 'STCW ', value: '235' },
  { label: 'SURVIVAL CRAFT (TEMPSC) ', value: '236' },
  { label: 'SURVIVAL CRAFT OPERATION TRAINING ', value: '237' },
  { label: 'TRANSFER BASKET & SWING ROPE', value: '238' },
  { label: 'UNDERWATER PATIENT EVACUATION TRAINING AND AQUATIC RESCUE (UPET)', value: '239' },
  { label: 'UNDERWATER WELDING (UW)', value: '240' },
  { label: 'VACCINE', value: '241' },
  { label: 'VHF RADIO CERTIFICATE', value: '242' },
  { label: 'WORKING AT HEIGHT', value: '243' },
]

export const employmentType = [ 'Full Time', 'Part Time', 'Contractual', 'Internship', 'Shift' ]

export const industrial = [
  { label: 'OIL & GAS', value: '1' },
]

export const subIndustiral = [
  { label: 'UPSTREAM', value: '1' },
  { label: 'DOWNSTREAM', value: '2' },
  { label: 'UPSTREAM & DOWNSTREAM', value: '3' },
]

export const achievement = [
  { id: 1, label: 'TURNAROUND (TA) / SHUTDOWN', value: '1' },
  { id: 2, label: 'TESTIMONIAL', value: '2' },
  { id: 3, label: 'AWARD / ACHIEVEMENT', value: '3' },
  { id: 4, label: 'HOOK UP AND COMMISSIONING', value: '4' },
  { id: 5, label: 'ENGINEERING PROCUREMENT', value: '5' },
]

export const experienceYear = [
  { label: '< 1 year', value: '0' },
  { label: '1-3 year', value: '1' },
  { label: '3-5 year', value: '2' },
  { label: '5-7 year', value: '3' },
  { label: '7-10 year', value: '4' },
  { label: '10-15 year', value: '5' },
  { label: '>15 year', value: '6' }
]

export const clientExperience = [
  { label: '1-3', value: '1' },
  { label: '3-5', value: '2' },
  { label: '5-10', value: '3' },
  { label: '10-15', value: '4' },
  { label: '>15', value: '5' }
]

export const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'ch', label: 'Chinese' },
  { value: 'bm', label: 'Bahasa Malaysia' }
]

export const jobCategory = [
  { id: 1, label: 'Oil & Gas', value: '1' },
]

export const jobType = [
  { id: 1, label: 'Part Time', value: '1' },
  { id: 2, label: 'Full Time', value: '2' },
  { id: 3, label: 'Contractual', value: '3' },
  { id: 4, label: 'Internship', value: '4' },
  { id: 5, label: 'Shift', value: '5' },
]

export const priceUnit = [
  { id: 1, label: 'Per Hour', value: '1' },
  { id: 2, label: 'Per Day', value: '2' },
  { id: 3, label: 'Per Week', value: '3' },
  { id: 4, label: 'Per Month', value: '4' },
  { id: 5, label: 'Per Year', value: '5' },
]

export const jobApplicationStatus = [
  { id: 1, label: 'Pending Approval', value: '1', color: 'orange' },
  { id: 2, label: 'Approved', value: '2', color: 'green' },
  { id: 3, label: 'Rejected', value: '3', color: 'red' },
  { id: 4, label: 'Fully Paid & Completed', value: '4', color: 'blue' },
]

export const workDurationOptions = [
  { id: 1, label: '1 - 3', value: '1' },
  { id: 2, label: '3 - 7', value: '2' },
  { id: 3, label: '7 - 14', value: '3' },
  { id: 4, label: '14 - 30', value: '4' },
  { id: 5, label: '30 - 45', value: '5' },
  { id: 6, label: '45 - 60', value: '6' },
  { id: 7, label: '60 - 90', value: '7' },
  { id: 8, label: '>90', value: '8' }
]

export const quickFilter = [ 'High Pay', 'Immediate Hiring', 'Event', 'Indoor', 'Meal Provided', 'High Demand', 'High Allowance', 'Uniform Provided' ]