import React from 'react'
import './navbar.scss'
import { Link } from 'react-router-dom'
import { ClientItem,AdminItem } from './SidebarItem';

const Navbar = ({
  user,
  sidebar,
  language
}) => {

  const renderSideItem = () => {
    switch( user.role ){
      case 'Admin':
        return AdminItem;
      case 'Client':
        return ClientItem;
      default:
        return [];
    }
  }

  const renderActiveItem = path => {
    if( window.location.href.includes( path ) )
      return 'item-bars-active'
    else 
      return ''
  }

  return (
    <nav className={sidebar ? 'nav-menu' : 'nav-menu-expand'} style={{ paddingTop: 80 }}>
      <ul className='nav-menu-item'>
        {
          renderSideItem().map((item, index) => {
            return (
              <li key={index} className={ `item-bars ${ renderActiveItem( item.path ) }` } >
                <Link to={ item.path } className={sidebar?'nav-text nav-text-collapse':'nav-text nav-text-expand'} >
                  <span>{item.icon}</span>
                  <span className={sidebar ? 'nav-item-text' : 'nav-item-text-expand'} > {item.title} </span>
                </Link>
              </li>
            )
          })
        } 
        </ul>
    </nav >
  )
}

export default Navbar