import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post } from 'utils/axios';
import { priceUnit } from 'utils/dictionary';

const HOC = ( WrappedComponent ) => {

  class WithHOC extends Component {

    state={
      loading: false,
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      showUpdateModal: false,
      showDeleteModal: false,
      showCreateModal: false,

      subscriptions: [],
      selectedSubscription: {},
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
      },
      snackbarMessage: success
    }, () => callback() )
    onChangeSubManagementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getSubscriptions = () => Get(
      `/admin-getSubscriptionPlans`,
      this.getSubscriptionsSuccess,
      this.getSubscriptionsError,
      this.load
    )
    getSubscriptionsSuccess = response => {
      let tempData = []
      response.data.map( item => {
        tempData.push({
          ...item,
          price_unit: _.find( priceUnit, { id: Number( item.price_unit ) })?.label ?? 'N/A'
        })
      })
      this.setState({ subscriptions: tempData })
    }
    getSubscriptionsError = error => this.requestError( error )

    getSelectedSubscription = id => Get(
      `/admin-getSubscriptionPlans?id=${ id }`,
      this.getSelectedSubscriptionSuccess,
      this.getSelectedSubscriptionError,
      this.load
    )
    getSelectedSubscriptionSuccess = response => this.setState({
      selectedSubscription: response.data,
      showUpdateModal: true
    })
    getSelectedSubscriptionError = error => this.requestError( error )

    updateSubscription = dataToSubmit => Post(
      `/admin-updateSubscriptionPlan?id=${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateSubscriptionSuccess,
      this.updateSubscriptionError,
      this.load
    )
    updateSubscriptionSuccess = response => this.requestSuccess( 'Subscripton Updated', () => {
      this.setState({ showUpdateModal: false })
      this.getSubscriptions()
    } )
    updateSubscriptionError = error => this.requestError( error )

    createSubscription = dataToSubmit => Post(
      `/admin-createSubscriptionPlan`,
      dataToSubmit,
      this.createSubscriptionSuccess,
      this.createSubscriptionError,
      this.load
    )
    createSubscriptionSuccess = response => this.requestSuccess( 'Subscription Created', () => {
      this.setState({ showCreateModal: false })
      this.getSubscriptions()
    } )
    createSubscriptionError = error => this.requestError( error )

    deleteSubscription = id => Post(
      `/admin-deleteSubscriptionPlan`,
      { id },
      this.deleteSubscriptionSuccess,
      this.deleteSubscriptionError,
      this.load
    )
    deleteSubscriptionSuccess = () => this.requestSuccess( 'Subscription Deleted', () => {
      this.setState({ showDeleteModal: false })
      this.getSubscriptions()
    } )
    deleteSubscriptionError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            { ... this.state }
            onLoadSubManagement={ this.state.loading }

            getSubscriptions={ this.getSubscriptions }
            updateSubscription={ this.updateSubscription }
            createSubscription={ this.createSubscription }
            deleteSubscription={ this.deleteSubscription }
            getSelectedSubscription={ this.getSelectedSubscription }
            onChangeSubManagementHOC={ this.onChangeSubManagementHOC } />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC );
}

export default HOC