import React, { Component } from 'react'
import Moment from 'moment'
import { connect } from 'react-redux'
import _ from 'lodash'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios';
import { industrial, jobType, priceUnit } from 'utils/dictionary';

const HOC = ( WrappedComponent ) => {

  class WithHOC extends Component {

    state={
      loading: false,
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      jobListing: [],
      selectedJobListing: {},
      showJobListing: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
      },
      snackbarMessage: success
    }, () => { callback && callback() })
    onChangeJobListingHOC = ( val, context ) => this.setState({ [ context ]: val })

    getJobListings = () => Get(
      `/admin-jobListing-getJobs`,
      this.getJobListingsSuccess,
      this.getJobListingsError,
      this.load
    )
    getJobListingsSuccess = response => {
      let tempData = []
      _.map( response.data, ( item ) => {
        tempData.push({
          ... item,
          start_date: Moment( item.start_date, 'YYYY/MM/DD' ).format( 'DD MMM YYYY' ),
          end_date: Moment( item.end_date, 'YYYY/MM/DD' ).format( 'DD MMM YYYY' ),
          category: _.find( industrial, { value: item.categoty_id })?.label ?? '-',
          job_type: _.find( jobType, { value: item.job_type })?.label ?? '-',
        })
      })
      this.setState({ jobListing: tempData })
    }
    getJobListingsError = error => this.requestError( error )

    getSelectedJobListing = ( id ) => Get(
      `/admin-jobListing-getListingDetail?id=${ id }`,
      this.getSelectedJobListingSuccess,
      this.getSelectedJobListingError,
      this.load
    )
    getSelectedJobListingSuccess = response => this.setState({
      selectedJobListing: {
        ...response.data,
        duration: `${ response.data.start_date } - ${ response.data.end_date }`,
        price: `RM ${ response.data.price } ${ _.find( priceUnit, { value: response.data.price_unit })?.label ?? '' }`,
        category: _.find( industrial, { value: response.data.categoty_id })?.label ?? '-',
        job_type: _.find( jobType, { value: response.data.job_type })?.label ?? '-',
      },
      showJobListing: true
    })
    getSelectedJobListingError = error => this.requestError( error )

    updateJobListingStatus = datatToSubmit => {
      Post(
        `/admin-jobListing-updateJobStatus`,
        datatToSubmit,
        this.updateJobListingStatusSuccess,
        this.updateJobListingStatusError,
        this.load
      )
    }
    updateJobListingStatusSuccess = () => this.requestSuccess( 'Job Listing status updated', () => {
      this.setState({ showJobListing: false })
      this.getJobListings()
    })
    updateJobListingStatusError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            { ... this.state }
            onLoadJobListing={ this.state.loading }

            getJobListings={ this.getJobListings }
            getSelectedJobListing={ this.getSelectedJobListing }
            updateJobListingStatus={ this.updateJobListingStatus }
            onChangeJobListingHOC={ this.onChangeJobListingHOC } />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC );
}

export default HOC