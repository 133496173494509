import React, { useEffect } from "react";
import { LuBriefcase, LuUser, LuMail, LuPhone, LuEdit3, LuInfo } from 'react-icons/lu'

import EmptyState from "components/EmptyState";
import LoadingModal from "components/LoadingModal";
import PaginatedTable from "components/PaginatedTable";
import ModuleHeader from 'components/Card/ModuleHeader';
import JobListingForm from './form'

import JobListingHOC from './actions';

const columnData = [
  { header: 'Client', renderColumn: rowData => (
    <div className="d-block">
      <p className="fs-14 fw-500">{ rowData.owner.displayName }</p>
      <p className="fs-12"><LuMail style={{ width: 14 }} /> { rowData.owner.email }</p>
      <p className="fs-12"><LuPhone style={{ width: 14 }} /> { rowData.owner.phone_number }</p>
    </div>
  ) },
  { header: 'Title', accessor: 'title', containerStyle: { width: '80%' } },
  { header: 'Category', accessor: 'category', containerStyle: { width: '50%' } },
  { header: 'Job Type', accessor: 'job_type', containerStyle: { width: '50%' } },
  { header: 'Work Duration', containerStyle: { width: '80%' },
    renderColumn: rowData => <span className="fs-12">{ `${ rowData.start_date } - ${ rowData.end_date }` }</span>
  },
  { header: 'Status', containerStyle: { width: '60%' }, renderColumn: rowData => rowData.status
    ? ( rowData.status === 'Active'
      ? <span className='badge badge-success'>Active</span>
      : <span className='badge badge-danger'>Inactive</span>)
    : <span className='badge badge-warning'>Pending Approval</span> },
]

const JobListingManagement = ({
  jobListing,
  selectedJobListing,
  showJobListing,
  showValidationModal,
  onLoadJobListing,

  getJobListings,
  getSelectedJobListing,
  updateJobListingStatus,
  onChangeJobListingHOC
}) => {

  useEffect(() => {
    getJobListings()
  }, [])

  return(
    <>
      <ModuleHeader
        title={ 'Job Listing Management' }
        renderIcon={ () => <LuBriefcase /> }
        description={
          <>
            This module is used to manage job listing created by recruiter.
            <ul style={{ paddingLeft: 30 }}>
              <li>Verify job listing after recruiter created.</li>
              <li>Hide job listing if recruiter against the policy.</li>
              <li>View job listing.</li>
            </ul>
          </>
        } />
      {
        jobListing.length > 0 && (
          <PaginatedTable
            rowData={ jobListing || [] }
            columnData={ columnData }
            className={ 'mt-2' }
            actionsColumn={[{
              name: 'View',
              renderActionIcon: <LuInfo />,
              onClick: rowData => getSelectedJobListing( rowData.id )
            }]} />
        )
      }
      {
        jobListing.length === 0 && <EmptyState title={ 'No Job Listing Created.' } />
      }
      {
        showJobListing && <JobListingForm
          selectedJobListing={ selectedJobListing }

          onClickSubmit={ data => updateJobListingStatus(data) }
          setOpen={ val => onChangeJobListingHOC( val, 'showJobListing' ) } />
      }
      { onLoadJobListing && <LoadingModal /> }
    </>
  )
}

export default JobListingHOC(JobListingManagement)