import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios';

const HOC = ( WrappedComponent ) => {

  class WithHOC extends Component {

    state={
      loading: false,
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      showUpdateModal: false,
      showDeleteModal: false,
      showCreateModal: false,
      showValidationModal: false,

      users: [],
      selectedUser: {},
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
      },
      snackbarMessage: success
    }, () => { callback && callback() })
    onChangeUserManagementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getUsers = () => Get(
      `/users-getUser`,
      this.getUsersSuccess,
      this.getUsersError,
      this.load
    )
    getUsersSuccess = response => this.setState({ users: response.data })
    getUsersError = error => this.requestError( error )

    getSelectedUser = ( uid, mode ) => Get(
      `/users-getUser?uid=${ uid }`,
      payload => this.getSelectedUserSuccess( payload, mode ),
      this.getSelectedUserError,
      this.load
    )
    getSelectedUserSuccess = ( response, mode ) => this.setState({
      selectedUser: response.data,
      ...(( mode === 'validate' && response.data.role_id !== 2 ) && { showValidationModal: true } ),
      ...( mode === 'edit' && { showUpdateModal: true } ),
    }, () => {
      if( mode === 'validate' && response.data.role_id === 2 ) {
        Promise.all([
          this.getJobExperience( response.data.uid ),
          this.getAchievement( response.data.uid ),
          this.getPositionPreferred( response.data.uid ),
          this.getSupportDocument( response.data.uid ),
        ]).then( () => {
          this.setState({ showValidationModal: true })
        })
      }
    })
    getSelectedUserError = error => this.requestError( error )

    getJobExperience = id => Get(
      `/users-getJobExperience?uid=${id}`,
      this.getJobExperienceSuccess,
      this.getJobExperienceError,
      this.load
    )
    getJobExperienceSuccess = payload => this.setState({ selectedUser: {
      ...this.state.selectedUser,
      jobExperience: payload.data
    }})
    getJobExperienceError = err => this.onToast( 'error', 'Error', err )

    getAchievement = id => Get(
      `/users-getAchievement?uid=${id}`,
      this.getAchievementSuccess,
      this.getAchievementError,
      this.load
    )
    getAchievementSuccess = payload => this.setState({ selectedUser: {
      ...this.state.selectedUser,
      achievements: payload.data
    }})
    getAchievementError = err => this.onToast( 'error', 'Error', err )

    getPositionPreferred = id => Get(
      `/users-getPositionPreferred?uid=${id}`,
      this.getPositionPreferredSuccess,
      this.getPositionPreferredError,
      this.load
    )
    getPositionPreferredSuccess = payload => this.setState({ selectedUser: {
      ...this.state.selectedUser,
      position_preffered: payload.data
    }})
    getPositionPreferredError = err => this.onToast( 'error', 'Error', err )

    getSupportDocument = id => Get(
      `/users-getSupportDocument?uid=${id}`,
      this.getSupportDocumentSuccess,
      this.getSupportDocumentError,
      this.load
    )
    getSupportDocumentSuccess = payload => this.setState({ selectedUser: {
      ...this.state.selectedUser,
      certifications: payload.data
    }})
    getSupportDocumentError = err => this.onToast( 'error', 'Error', err )

    createUser = dataToSubmit => Post(
      `/users-createUser`,
      dataToSubmit,
      this.createUserSuccess,
      this.createUserError,
      this.load
    )
    createUserSuccess = () => this.requestSuccess( 'User Created', () => {
      this.setState({ showCreateModal: false })
      this.getUsers()
    })
    createUserError = error => this.requestError( error )

    updateUser = dataToSubmit => Post(
      `/users-updateUser`,
      dataToSubmit,
      this.updateUserSuccess,
      this.updateUserError,
      this.load
    )
    updateUserSuccess = () => this.requestSuccess( 'User Updated', () => {
      this.getUsers()
    })
    updateUserError = error => this.requestError( error )

    validateUser = id => Post(
      `/users-validateUser`,
      { uid: id },
      this.validateUserSuccess,
      this.validateUserError,
      this.load
    )
    validateUserSuccess = response => this.requestSuccess( 'User Validated.', () => {
      this.setState({ showValidationModal: false })
      this.getUsers()
    })
    validateUserError = error => this.requestError( error )

    deleteUser = id => Delete(
      `/users-deleteUser?uid=${id}`,
      this.deleteUserSuccess,
      this.deleteUserError,
      this.load
    )
    deleteUserSuccess = response => this.requestSuccess( 'User Deleted.', () => {
      this.getUsers()
    })
    deleteUserError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            { ... this.state }
            onLoadUserManagement={ this.state.loading }

            getUsers={ this.getUsers }
            createUser={ this.createUser }
            updateUser={ this.updateUser }
            deleteUser={ this.deleteUser }
            validateUser={ this.validateUser }
            getSelectedUser={ this.getSelectedUser }
            onChangeUserManagementHOC={ this.onChangeUserManagementHOC } />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC );
}

export default HOC