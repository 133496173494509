
import { UPDATE_TEMPLATE } from './type'

let initialState = {
  version: '',
  updated_at: ''
};

const TemplateReducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case UPDATE_TEMPLATE:
      return {
        ...state,
        version: action.version,
        updated_at: action.updated_at
      }
    default: return state;
  }
}

export default TemplateReducer;