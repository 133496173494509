import React, { Component } from 'react'
import { connect } from 'react-redux'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios';

const HOC = ( WrappedComponent ) => {

  class WithHOC extends Component {

    state={
      loading: false,
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      showUpdateModal: false,
      showDeleteModal: false,
      showCreateModal: false,

      announcements: [],
      selectedAnnouncement: {},
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
      },
      snackbarMessage: success
    }, () => { callback && callback() })
    onChangeAnnouncementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getAnnouncements = () => Get(
      `/admin-announcement-getAnnouncement`,
      this.getAnnouncementsSuccess,
      this.getAnnouncementsError,
      this.load
    )
    getAnnouncementsSuccess = response => {
      this.setState({ announcements: response.data })
    }
    getAnnouncementsError = error => this.requestError( error )

    getSelectedAnnouncement = id => Get(
      `/admin-announcement-getAnnouncement?id=${ id }`,
      this.getSelectedAnnouncementSuccess,
      this.getSelectedAnnouncementError,
      this.load
    )
    getSelectedAnnouncementSuccess = response => this.setState({
      selectedAnnouncement: response.data,
      showUpdateModal: true
    })
    getSelectedAnnouncementError = error => this.requestError( error )

    createAnnouncement = dataToSubmit => Post(
      `/admin-announcement-createAnnouncement`,
      dataToSubmit,
      this.createAnnouncementSuccess,
      this.createAnnouncementError,
      this.load
    )
    createAnnouncementSuccess = () => {
      this.setState({ showCreateModal: false })
      this.getAnnouncements()
      this.requestSuccess( 'Announcement created' )
    }
    createAnnouncementError = error => this.requestError( error )

    updateAnnouncement = dataToSubmit => Post(
      `/admin-announcement-updateAnnouncement`,
      dataToSubmit,
      this.updateAnnouncementSuccess,
      this.updateAnnouncementError,
      this.load
    )
    updateAnnouncementSuccess = () => {
      this.setState({ showUpdateModal: false })
      this.getAnnouncements()
      this.requestSuccess( 'Announcement updated' )
    }
    updateAnnouncementError = error => this.requestError( error )

    deleteAnnouncement = id => Delete(
      `/admin-announcement-deleteAnnouncement?id=${ id }`,
      this.deleteAnnouncementSuccess,
      this.deleteAnnouncementError,
      this.load
    )
    deleteAnnouncementSuccess = () => {
      this.setState({ showDeleteModal: false })
      this.getAnnouncements()
      this.requestSuccess( 'Announcement deleted' )
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            { ... this.state }
            onLoadAnnouncement={ this.state.loading }

            getAnnouncements={ this.getAnnouncements }
            createAnnouncement={ this.createAnnouncement }
            updateAnnouncement={ this.updateAnnouncement }
            deleteAnnouncement={ this.deleteAnnouncement }
            getSelectedAnnouncement={ this.getSelectedAnnouncement }
            onChangeAnnouncementHOC={ this.onChangeAnnouncementHOC } />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC );
}

export default HOC