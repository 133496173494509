import React, { useEffect, useState } from "react";
import _ from 'lodash'
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Tab, Tabs, Modal, Button, Card } from "react-bootstrap"

import { priceUnit } from "utils/dictionary";
import { freelancerTabs, clientTabs, fieldData, freelanceFieldData, clientFieldData,
  bankFields, positionField, languageField, jobFieldData, achievementField, certificateField  } from "./assets";

const ValidationModal = ({
  selectedUser,

  setOpen,
  onClickSubmit,
}) => {

  const {
    bank_info = {},
    language = {},
    achievements = [],
    jobExperience = [],
    certifications = [],
    position_preffered = [],

    ssm_info = {},
    offer_letter = {},
  } = selectedUser
  
  const renderFieldData = ( data, field, index ) => {
    if( field.options ){
      let temp = _.find( field.options, { value: data[ field.value ] })
      return (
        <div key={ index } className="col-md-6">
          <label style={{ fontSize: 14, color: 'gray', marginBottom: 0 }}>{ field.label }</label>
          <p style={{ fontSize: 16, fontWeight: 500 }}>{ temp?.label ?? '-' }</p>
        </div>
      )
    } else if ( field.value === 'document' ){
      return(
        <div key={ index } className="col-md-6">
          <p style={{ fontSize: 14, color: 'gray', marginBottom: 0 }}>{ field.label }</p>
          { data.document_url 
            ? <img src={ data.document_url } style={{ width: 200, height: 'auto', borderRadius: 8 }} />
            : <p style={{ fontSize: 16, fontWeight: 500 }}>{ '-' }</p>
          }
        </div>
      )
    } else {
      return(
        <div key={ index } className="col-md-6">
          <label style={{ fontSize: 14, color: 'gray', marginBottom: 0 }}>{ field.label }</label>
          <p style={{ fontSize: 16, fontWeight: 500 }}>{ data[ field.value ] || '-' }</p>
        </div>
      )
    }
  }

  const renderTabContent = ( tab ) => {
    switch( tab ){
      case 'basic_information':
        return(
          <Card>
            <Card.Body>
              <div className="row">
                {
                  _.map( fieldData, ( field, index ) => ( renderFieldData( selectedUser, field, index ) ) )
                }
                {
                  selectedUser.role_id === 2 && _.map( freelanceFieldData, ( field, index ) => ( renderFieldData( selectedUser, field, index ) ) )
                }
                {
                  selectedUser.role_id === 3 && _.map( clientFieldData, ( field, index ) => ( renderFieldData( selectedUser, field, index ) ) )
                }
              </div>
            </Card.Body>
          </Card>
        )
      case 'bank_details':
        return(
          <Card>
            <Card.Body>
              <div className="row">
                {
                  _.map( bankFields, ( field, index ) => ( renderFieldData( bank_info, field, index ) ) )
                }
              </div>
            </Card.Body>
          </Card>
        )
      case 'position_preffered':
        return  position_preffered.length > 0
          ? _.map( position_preffered, ( position, index ) => (
            <Card className="mb-2">
              <Card.Body>
                <div className="row">
                  {
                    _.map( positionField, ( field, index ) => {
                      if( field.value === 'expected_salary' ){
                        let temp = _.find( priceUnit, { value: position.salary_unit })
                        return (
                          <div key={ index } className="col-md-6">
                            <label style={{ fontSize: 14, color: 'gray', marginBottom: 0 }}>{ field.label }</label>
                            <p style={{ fontSize: 16, fontWeight: 500 }}>{ temp?.label?? '' } { position[ field.value ] }</p>
                          </div>
                        )
                      } else {
                        return ( renderFieldData( position, field, index ) )
                      }
                    })
                  }
                </div>
              </Card.Body>
            </Card>
          ))
        : <p style={{ fontSize: 16, fontWeight: 500 }}>No Position Preferred</p>
      case 'language_profile':
        return(
          <Card>
            <Card.Body>
              {
                _.map( languageField, ( field, index ) => (
                  <div key={ `language-${ index }` } className="mb-3">
                    <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 0, marginLeft: 5 }}>{ field.label }</p>
                    { [1,2,3,4,5].map( ( item, starIndex ) => {
                      const highlight = `${starIndex + 1}` <= ( language[ field.value ] || 0 )
                      if( highlight ){
                        return ( <AiFillStar size={30} color={ '#FDC500' }/> )
                      } else {
                        return ( <AiOutlineStar size={30} color={ 'gray' }/> )
                      }
                    })}
                  </div>
                ))
              }
            </Card.Body>
          </Card>
        )
      case 'job_experience':
        return  jobExperience.length > 0
          ? _.map( jobExperience, ( exp, index ) => (
            <Card className="mb-2">
              <Card.Body>
                <div className="row">
                  {
                    _.map( jobFieldData, ( field, index ) => ( renderFieldData( exp, field, index ) ) )
                  }
                </div>
              </Card.Body>
            </Card>
          ))
        : <p style={{ fontSize: 16, fontWeight: 500 }}>No Job Experience</p>
      case 'achievement':
        return  achievements.length > 0
          ? _.map( achievements, ( achievement, index ) => (
            <Card className="mb-2">
              <Card.Body>
                <div className="row">
                  {
                    _.map( achievementField, ( field, index ) => ( renderFieldData( achievement, field, index ) ) )
                  }
                </div>
              </Card.Body>
            </Card>
          )) 
        : <p style={{ fontSize: 16, fontWeight: 500 }}>No Achievement</p>
      case 'certification':
        return  certifications.length > 0
          ? _.map( certifications, ( achievement, index ) => (
            <Card className="mb-2">
              <Card.Body>
                <div className="row">
                  {
                    _.map( certificateField, ( field, index ) => ( renderFieldData( achievement, field, index ) ) )
                  }
                </div>
              </Card.Body>
            </Card>
          )) 
        : <p style={{ fontSize: 16, fontWeight: 500 }}>No Certification</p>
      case 'offer_letter':
        return !_.isEmpty( offer_letter )
          ? (
              <Card>
                <Card.Body>
                  <img src={ offer_letter.document_url } style={{ width: '100%', height: 'auto' }} />
                  <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 0, marginLeft: 5 }}>{ offer_letter?.document_filename }</p>
                </Card.Body>
              </Card>
            )
          : <p style={{ fontSize: 16, fontWeight: 500 }}>No Offer Letter</p>
      case 'ssm_information':
        return !_.isEmpty( ssm_info )
          ? (
              <Card>
                <Card.Body>
                  <img src={ ssm_info.document_url } style={{ width: '100%', height: 'auto' }} />
                  <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 0, marginLeft: 5 }}>{ ssm_info?.document_filename }</p>
                </Card.Body>
              </Card>
            )
          : <p style={{ fontSize: 16, fontWeight: 500 }}>No SSM Information</p>
      default:
        return null
    }
  }

  return (
    <Modal show={ true } onHide={() => setOpen(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Validate User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="basic_information"
          className="mb-3">
          {
            selectedUser.role_id === 2 && _.map( freelancerTabs, ( tab, index ) => (
              <Tab key={ index } eventKey={ tab.eventKey } title={ tab.label }>
                { renderTabContent( tab.eventKey ) }
              </Tab>
            ))
          }
          {
            selectedUser.role_id === 3 && _.map( clientTabs, ( tab, index ) => (
              <Tab key={ index } eventKey={ tab.eventKey } title={ tab.label }>
                { renderTabContent( tab.eventKey ) }
              </Tab>
            ))
          }
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={ () => onClickSubmit( selectedUser.uid ) } >
          Verify
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ValidationModal