
import { LOGIN_USER } from './type'

let initialState = {};

const CurrentUserReducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case LOGIN_USER:
      return {
        ...state,
        ...action.payload
      }
    default: return state;
  }
}

export const UpdateCurrentUser = user => ({
  type: LOGIN_USER,
  payload: user
})

export default CurrentUserReducer;