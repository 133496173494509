import React from 'react'
import { PlaylistRemove } from '@mui/icons-material'

import './index.scss'

const EmptyState = ({
  title,
  description
}) => {

  return(
    <div className='at-empty-state-cont'>
      <div className='at-empty-state__icon'>
        <PlaylistRemove />
      </div>
      <p className='title'>{ title }</p>
      {
        description && <p className='description'>{ description }</p>
      }
    </div>
  )
}

export default EmptyState;