const transactions = {
  'Email': 'Emel',
  'Password': 'Kata Laluan',
  'Login': 'Log Masuk',
  'Forget Password': 'Lupa Kata Laluan',
  'Update Profile': 'Kemas Kini Profil',
  'Change Email/Password': 'Tukar E-mel/Kata Laluan',
  'Sign Out': 'Log Keluar',
  'Project Management': 'Pengurusan Projek',
  'This module use to manage all the project in the system.': 'Modul ini digunakan untuk mengurus semua projek dalam sistem.',
  'Total Project': 'Jumlah Projek',
  'Active Project': 'Projek Aktif',
  'Pending Project': 'Projek Belum Selesai',
  'Rejected Project': 'Projek Ditolak',
  'Project Name': 'Nama Projek',
  'Description': 'Penerangan',
  'Implementing Agency': 'Agensi Pelaksana',
  'State': 'Negeri',
  'Status': 'Status',
  'Create Project': 'Projek Baharu',
  'Delete': 'Padam',
  'Assessor': 'Penilai',
  'Project Leader': 'Ketua Projek',
  'Secretariat': 'Urus setia',
  'Start Year': 'Tahun Mula',
  'Funder': 'Pembiaya',
  'Create New Project': 'Projek Baharu',
  'Update Project': 'Kemas Kini Projek',
  'Close': 'Tutup',
  'Submit': 'Hantar',
  'Actions': 'Tindakan',
  'Milestone Title': 'Tajuk Milestone',
  'KPI(%)': 'KPI(%)',
  'Start Date': 'Tarikh Mula',
  'End Date': 'Tarikh Tamat',
  'Utilization Fund': 'Dana Penggunaan',
  'Stage Title' : 'Tajuk Fasa',
  'Stage Desrciption': 'Penerangan Fasa',
  'Overview': 'Keseluruhan',
  'Stage & Milestone': 'Peringkat & Pencapaian',
  'KPI': 'KPI',
  'Disbursement': 'Pembayaran',
  'Project Progress Assessment': 'Penilaian Kemajuan Projek',
  'Site Audit Assessment': 'Penilaian Audit Tapak',
  'Overall Monitoring Analysis': 'Analisis Pemantauan Keseluruhan',
  'Project Details': 'Butiran Projek',
  'Go to Stage & Milestone': 'Pergi ke Peringkat & Pencapaian',
  'No stage and milestone define. Please go to ': 'Tiada peringkat dan pencapaian yang ditentukan. Sila pergi ke ',
  ' tab to create new stage and milestone.': ' tab untuk mencipta peringkat dan pencapaian baharu.',
  'No KPI define. Please go to ': 'Tiada definisi KPI. Sila pergi ke ',
  ' tab to create new KPi for the project.': ' tab untuk mencipta KPi baharu untuk projek itu.',
  'Go to KPI': 'Pergi ke KPI',
  'Activity Report': 'Laporan Aktiviti',
  'No Activity Report.': 'Tiada Laporan Aktiviti.',
  'Go to Disbursement': 'Pergi ke Pembayaran',
  'No disbursement define. Please go to ': 'Tiada penetapan pembayaran. Sila pergi ke ',
  ' tab to create new disbursement.': ' tab untuk membuat pembayaran baharu.',
  'Utilization Progress': 'Kemajuan Penggunaan',
  'No utilization define.': 'Tiada definisi penggunaan.',
  'Project Started': 'Projek Dimulakan',
  'Next Submission Date': 'Tarikh Penyerahan Seterusnya',
  'Current Stage': 'Peringkat Semasa',
  'Current Milestone': 'Pencapaian Semasa',
  'Actual KPI(%)': 'KPI Sebenar(%)',
  'Actual Start Date': 'Tarikh Mula Sebenar',
  'Actual End Date': 'Tarikh Tamat Sebenar',
  'Fund Utilization': 'Penggunaan Dana',
  'Submitted Date': 'Tarikh Dihantar',
  'Estimate Completion(%)': 'Anggaran Penyiapan(%)',
  'Video Folder Link': 'Pautan Folder Video',
  'Publication Folder Link': 'Pautan Folder Penerbitan',
  'Image Folder Link': 'Pautan Folder Imej',
  'Other Folder Link': 'Pautan Folder Lain',
  'Date': 'Tarikh',
  'Funding': 'Pembiayaan',
  'Amount': 'Jumlah',
  'Completion(%)': 'Selesai(%)',
  'Document': 'Dokumen',
  'Utilization(RM)': 'Penggunaan(RM)',
  'Utilization(%)': 'Penggunaan(%)',
  'Key Performance Indicator(KPI)': 'Petunjuk Prestasi Utama(KPI)',
  'Notes': 'Nota',
  'Add New Stage': 'Tambah Peringkat Baharu',
  'Project Timeline': 'Garis Masa Projek',
  'You have unsaved changes.': 'Anda mempunyai perubahan yang belum disimpan.',
  'Please click ': 'Sila klik',
  'Update': 'Kemas Kini',
  ' to make the changes permanently. The changes will discard if you browse to other tab.': ' untuk membuat perubahan secara kekal. Perubahan akan dibuang jika anda menyemak imbas ke tab lain.',
  'Edit Stage': 'Kemas Kini Peringkat',
  'Stage Timeline': 'Garis Masa Peringkat',
  'Add Milestone': 'Tambahkan Pencapaian',
  'No Disbursement': 'Tiada Pengeluaran',
  "Please create disbursement before stage and milestone. You may also leave 'Utilization Fund' empty.": "Sila buat pembayaran sebelum peringkat dan peristiwa penting. Anda juga boleh membiarkan 'Dana Penggunaan' kosong.",
  'No.': 'No.',
  'Support Document': 'Dokumen Sokongan',
  'Submission Date': 'Tarikh menghantar',
  'Create KPI': 'KPI Baharu',
  'No action required': 'Tiada tindakan diperlukan',
  'No Result Found': 'Tiada Keputusan Ditemui',
  'Showing from ': 'Menunjukkan dari ',
  'Project KPI': 'Projek KPI',
  'Create New KPI': 'KPI Baharu',
  'Edit KPI': 'Kemas Kini KPI',
  'Approve': 'Terima',
  'Stage': 'Peringkatan',
  'Milestone': 'Pencapaian',
  'Date of Disbursement': 'Tarikh Pengeluaran',
  'Project Completion(%)': 'Penyiapan Projek(%)',
  'Disbursement Progress': 'Kemajuan Pengeluaran',
  'Create New Disbursement': 'Pengeluaran Baharu',
  'Edit Disbursement': 'Kemas Kini Pengeluaran',
  'Amount(RM)': 'Jumlah(RM)',
  'Funding(%)': 'Pembiayaan(%)',
  'Total User': 'Jumlah Pengguna',
  'Total Admin/Secretariat': 'Jumlah Pentadbir/Urusetia',
  'Total Assessor': 'Jumlah Penilai',
  'Total Project Leader': 'Jumlah Ketua Projek',
  'Mobile No': 'Nombor telefon bimbit',
  'Role': 'Peranan',
  'Name': 'Nama',
  'Create New User': 'Pengguna Baharu',
  'Update User': 'Kemas Kini Pengguna',
  'Create User': 'Pengguna Baharu',
  'User Management': 'Pengurusan Pengguna',
  'This module use to manage all the user in the system.': 'Modul ini digunakan untuk mengurus semua pengguna dalam sistem.',
  'Display Name': 'Nama Paparan',
  'Project': 'Projek',
  'Mobile No.': 'Nombor telefon bimbit.',
  'Are you sure?': 'Adakah anda pasti?',
  'Cancel': 'Batal',
  'Confirm': 'Terima',
  'You are going to remove the selected project ': 'Anda akan padam projek yang dipilih',
  'This action is non-reversible.': 'Tindakan ini tidak boleh diterbalikkan.',
  ' from the system and this will also remove the project from Assessor and Project Leader.': ' daripada sistem dan ini juga akan padam projek daripada Penilai dan Ketua Projek.',
  'You are going to remove the selected disbursement record from the project?': 'Anda akan padam rekod pengeluaran yang dipilih daripada projek?',
  'You are going to remove the selected kpi from the project?': 'Anda akan padam kpi yang dipilih daripada projek?',
  'You are going to remove the selected stage ': 'Anda akan mengalih keluar peringkat yang dipilih ',
  ' from the project.': ' daripada projek itu.',
  'You are going to remove the selected user ': 'Anda akan mengalih keluar pengguna yang dipilih ',
  ' from the system and this will also remove the user from all related project.': ' daripada sistem dan ini juga akan mengalih keluar pengguna daripada semua projek yang berkaitan.',
  'Assessment By': 'Penilaian Oleh',
  'Total Score': 'Jumlah Markah',
  'Rate at': 'Kadar pada',
  'View': 'Lihat',
  'Preview Document': 'Pratonton Dokumen',
  'Project Assessment': 'Penilaian Projek',
  'Year': 'Tahun',
  'Assessor Name': 'Nama Penilai',
  'Assessment': 'Penilaian',
  'Based on your assessment, kindly provide your comment as follow:': 'Berdasarkan penilaian anda, sila berikan ulasan anda seperti berikut:',
  'Things/Achievements can be proud of. (Commendable Areas)': 'Perkara/Pencapaian yang boleh dibanggakan. (Commendable Areas)',
  'Things to be fixed. (Affirmative Areas)': 'Perkara yang diperbaiki. (Affirmative Areas)',
  'Other Remarks': 'Ucapan Lain',
  'Overall Grade': 'Gred Keseluruhan',
  'Akuan Panel Penilai': 'Penilai Panel Akuan',
  'Overall Comments & Other Remarks': 'Komen Keseluruhan & Kenyataan Lain',
  'View in PDF Viewer': 'Lihat dalam PDF Viewer',
  'Rated at': 'Dinilai pada',
  'Rating': 'Penilaian',
  'Not Started': 'Tidak Bermula',
  'On Track': 'Atas Landasan',
  'Over Achievement': 'Lebih Pencapaian',
  'Delayed < 2 weeks': 'Tertunda < 2 minggu',
  'Delayed > 2 weeks': 'Tertunda > 2 minggu',
  'Completed': 'Selesai',
  'Pending': 'Belum Selesai',
  'Submitted': 'Telah Diserah',
  'Complete': 'Selesai',
  'On-Progress': 'Dalam Kemajuan',
  'In Review':'Dalam Tinjauan',
  'Poor': 'Teruk',
  'Exceed Target': 'Melebihi Sasaran',
  'Significantly Exceed Target': 'Melebihi Sasaran dengan ketara',
  'On Target': 'Pada sasaran',
  'Below Target': 'Di Bawah Sasaran',
  'Assessment Is Not Made': 'Penilaian Tidak Dibuat',
  'Preview': 'Pratonton',
  'Previous': 'Balik',
  'Next': 'Seterus',
  'Assessment on Project Reporting': 'Penilaian pada Pelaporan Projek',
  'Assessment on Project Achievement': 'Penilaian terhadap Pencapaian Projek',
  'Assessment on Project Team': 'Penilaian ke atas Pasukan Projek',
  'Assessment on Collaboration Partners': 'Penilaian ke atas Rakan Kerjasama',
  'Assessment on Community Beneficiary': 'Penilaian ke atas Penerima Komuniti',
  'Project performance reporting updated as specified': 'Pelaporan prestasi projek dikemaskini seperti ditetapkan' ,
  'Activity reporting updated as specified': 'Pelaporan aktiviti dikemaskini seperti ditetapkan',
  'Key Performance Indicator (KPI) reporting updated as specified': 'Pelaporan Key Performance Indicator (KPI) dikemaskini seperti ditetapkan',
  'Financial reporting updated as prescribed': 'Pelaporan kewangan dikemaskini seperti ditetapkan',
  'Submit and upload supporting documents': 'Mengemukakan dan memuat naik dokumen sokongan',
  'Objective Achievement': 'Pencapaian Objektif',
  'Milestone Achievement': 'Pencapaian Milestone',
  'Timeline of delivery (timing performance)': 'Garis masa penghantaran (prestasi masa)',
  'Project financial performance': 'Prestasi kewangan projek',
  'Quantitative Project Output': 'Output Projek Kuantitatif',
  'Quantitative Project Outcome': 'Hasil Projek Kuantitatif',
  'Qualitative Project Output': 'Output Projek Kualitatif',
  'Qualitative Project Outcome': 'Hasil Projek Kualitatif',
  'Project sustainability': 'Kemampanan projek',
  'Overall Assessment': 'Penilaian Keseluruhan',
  'Level of Knowledge being transferred solved project problems': 'Tahap Pengetahuan yang dipindahkan menyelesaikan masalah projek',
  'Coaching and advices that has been given': 'Bimbingan dan nasihat yang telah diberikan',
  'In depth knowledge on the project': 'Pengetahuan mendalam tentang projek',
  'Commitment and ethics shown by team to the project': 'Komitmen dan etika yang ditunjukkan oleh pasukan kepada projek',
  'Overall achievement by the team project': 'Pencapaian keseluruhan oleh projek pasukan',
  'Acceptance by the Collaborative Patners': 'Penerimaan oleh Patner Kolaboratif',
  'Understanding of the project': 'Pemahaman tentang projek',
  'Cooperation and commitment showed by Collaborative Patners': 'Kerjasama dan komitmen ditunjukkan oleh Collaborative Patners',
  'Acceptance by the Community Beneficiary': 'Penerimaan oleh Penerima Komuniti',
  'Cooperation and commitment showed by Community Beneficiary': 'Kerjasama dan komitmen ditunjukkan oleh Penerima Komuniti',
  'Projects result in practice change and empowered communities': 'Projek menghasilkan perubahan amalan dan komuniti diperkasakan',
  'IMPACT4C system achievements': 'IMPACT4C system achievements',
  'Proceeds to third parties': 'Hasil kepada pihak ketiga',
  'Quarter': 'Suku',
  'Create Site Audit': 'Audit Tapak Baharu',
  'Based on your assessment, kindly provide your comment as follows:': 'Berdasarkan penilaian anda, sila berikan ulasan anda seperti berikut:',
  'Physical achievement of infrastructure reaches 50%-75% of project progress as reported.': 'Pencapaian fizikal infrastruktur mencapai 50%-75% kemajuan projek seperti yang dilaporkan.',
  'Equipment and technology equipment is supplied and functional as specified asset and inventory records.': 'Kelengkapan peralatan dan teknologi dibekalkan serta berfungsi seperti rekod aset dan inventori yang dinyatakan.',
  'Infrastructure and equipment are provided to be used and benefited by the community.': 'Infrastruktur dan kelengkapan peralatan dibekalkan digunakan dan dimanfaatkan oleh komuniti.',
  'The community gives feedback on project achievements and benefits are received.': 'Komuniti memberi maklumbalas terhadap pencapaian projek dan manfaat diterima.',
  'Things to fix': 'Perkara untuk diperbaiki',
  'I confirm the assessment was made.': 'Saya mengesahkan penilaian tersebut dibuat.',
  'I hereby confirm the evaluation confirm the quotation documents and have examined all the documents submitted.': 'Dengan ini saya mengesahkan penilaian mengesahkan dokumen sebut harga dan telah meneliti semua dokumen yang dikemukakan.',
  'Site Audit Assessmennt': 'Penilaian Audit Tapak',
  'Unsatisfactory': 'Tidak Memuaskan',
  'Satisfactory': 'Memuaskan',
  'Good': 'Baik',
  'Very Good': 'Sangat Bagus',
  'Excellent': 'Cemerlang',
  'Secretariat/Admin': 'Pentadbir/Urusetia',
  'Create Analysis' : 'Analisis Baharu',
  'Overall Monitoring Assessment': 'Penilaian Pemantauan Keseluruhan',
  'Project Progress Analysis': 'Analisis Kemajuan Projek',
  'Total Score(%)': 'Jumlah Markah(%)',
  '1. Things/Achievements you can be proud of. (Commendable Areas)': '1. Perkara/Pencapaian yang boleh dibanggakan. (Commendable Areas)',
  '2. Things to fix. (Affirmative Areas)': '2. Perkara untuk diperbaiki. (Affirmative Areas)',
  '3. Other Remarks': '3. Ucapan Lain',
  '1. Physical achievement of the infrastructure reached 50%-75% of project progress as reported.': '1. Pencapaian fizikal infrastruktur mencapai 50%-75% kemajuan projek seperti yang dilaporkan.',
  '2. Equipment and technology equipment is supplied and functions as specified asset and inventory records.': '2. Kelengkapan peralatan dan teknologi dibekalkan serta berfungsi seperti rekod aset dan inventori yang dinyatakan.',
  '3. Infrastructure and equipment are provided to be used and benefited by the community.': '3. Infrastruktur dan kelengkapan peralatan dibekalkan digunakan dan dimanfaatkan oleh komuniti.',
  '4. The community gives feedback on project achievements and benefits received.': '4. Komuniti memberi maklumbalas terhadap pencapaian projek dan manfaat diterima.',
  '5. Things to fix': '5. Perkara untuk diperbaiki',
  'Project Working Community (PWC)': 'Komuniti Kerja Projek (PWC)',
  'Project Steering Community (PSC)': 'Komuniti Pemandu Projek (PSC)',
  'There is a pending assessment for': 'Ada penilaian belum selesai untuk',
  'There is a pending assessment': 'Ada penilaian belum selesai',
  'Edit': 'Kemas Kini',
  'Pending Task': 'Tugas Belum Selesai',
  'Project Performance & Activity Report': 'Laporan Prestasi & Aktiviti Projek',
  'Site Audit Report': 'Laporan Audit Tapak',
  'Project Rating': 'Penilaian Projek',
  'Filter and Search': 'Tapis dan Cari',
  'Search': 'Cari',
  'Reset': 'Tetapkan semula',
  'Program': 'Program',
  'No stage and milestone define.': 'Tiada peringkat dan pencapaian yang ditentukan.',
  'No KPI define.': 'Tiada definisi KPI.',
  'No disbursement define.': 'Tiada penetapan pembayaran.',
  'This module use to show all the project in the system and allow Project Leader to submit document and progress.':  'Modul ini digunakan untuk menunjukkan semua projek dalam sistem dan membenarkan Ketua Projek menyerahkan dokumen dan kemajuan.',
  'Project Performance': 'Prestasi Projek',
  'Financial': 'Kewangan',
  'No stage and milestone define. Please do inform admin to create stage and milstone for your project.': 'Tiada peringkat dan pencapaian yang ditentukan. Sila maklumkan kepada pentadbir untuk membuat pentas dan batu permata untuk projek anda.',
  'No KPI define. Please do inform admin to create KPI for your project.': 'Tiada definisi KPI. Sila maklumkan kepada admin untuk membuat KPI untuk projek anda.',
  'No activity report define. Please go to': 'Tiada laporan aktiviti menentukan. Sila pergi ke',
  'Go to Activity Report': 'Pergi ke Laporan Aktiviti',
  'tab to create new activity report.': 'tab untuk membuat laporan aktiviti baharu.',
  'No disbursement define. Please do inform admin to create stage and milstone for your project.': 'Tiada penetapan pembayaran. Sila maklumkan kepada pentadbir untuk membuat pentas dan batu permata untuk projek anda.',
  'No utilization define. Please go to': 'Tiada definisi penggunaan. Sila pergi ke',
  'tab to create new utilization records.': 'tab untuk mencipta rekod penggunaan baharu.',
  'Go to Financial': 'Pergi ke Kewangan', 
  'No Disbursment define': 'Takrifan Tiada Pengeluaran',
  'Please do inform admin to create Disbursement for your project': 'Sila maklumkan kepada pentadbir untuk membuat Pengeluaran untuk projek anda',
  'No KPI define': 'Tiada definisi KPI',
  'Please do inform admin to create KPI for your project': 'Sila maklumkan kepada pentadbir untuk membuat KPI untuk projek anda',
  'No stage and milestone define': 'Tiada peringkat dan pencapaian yang ditentukan',
  'Please do inform admin to create stage and milstone for your project': 'Sila maklumkan kepada pentadbir untuk membuat pentas dan batu permata untuk projek anda',
  'Edit Completion Record': 'Edit Rekod Penyiapan',
  'Link': 'Pautan',
  'Create Activity Report': 'Laporan Aktiviti Baharu',
  'Month': 'Bulan',
  'File Upload': 'Fail Dimuatnaik',
  'Edit Activity Report': 'Kemaskini Laporan Aktiviti',
  'Draft': 'Draf',
  'KPI Submission': 'Penyerahan KPI',
  'Create New Utilization': 'Penggunaan Baharu',
  'Edit Utilization': 'Kemaskini Penggunaan',
  'SYSTEM e-Pantau': 'SISTEM e-Pantau',
  'Brief information on the e-Pantau system': 'Maklumat ringkas mengenai sistem e-Pantau',
  'Introduction': 'Pengenalan',
  'e-Pantau is a web-based system that aims to monitor the performance of program implementation by the project team (implementing agency). The e-Pantau system is a data bank that contains information on the entire program such as performance throughout the program, program progress reports and program evaluation.': 'e-Pantau adalah satu sistem berasaskan web yang bertujuan untuk memantau prestasi pelaksanaan program oleh pasukan projek (agensi pelaksana). Sistem e-Pantau merupakan bank data yang mengandungi maklumat keseluruhan program seperti prestasi sepanjang program, laporan kemajuan program dan penilaian program.',
  'PURPOSE of e-Pantau': 'TUJUAN e-Pantau',
  'Giving key information to stake holders/agencies, especially in terms of the overall achievement of the implemented program.': 'Memberi informasi utama kepada pemegang taruh/agensi khususnya dalam aspek pencapaian keseluruhan program yang dilaksanakan.',
  'Facilitating the process of program monitoring and evaluation through systematic and structured information storage methods.': 'Memudahkan proses pemantauan dan penilaian program melalui kaedah penyimpanan maklumat yang sistematik dan berstruktur.',
  'Simplifying the process of compiling reports in the form of data for each phase implemented in the program': 'Memudahkan proses penyusunan laporan dalam bentuk data bagi setiap fasa yang dilaksanakan dalam program',
  'To Dashboard': 'Ke Papan Pemuka'
}

export const getTranslation = ( language, text ) => {
  if( language === 'BM' ){
    return transactions[ text ];
  } else {
    return text
  }
}