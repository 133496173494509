import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signOut, getAuth } from "firebase/auth";

import CustomSnackbar from 'components/Snackbar';

import { Post, Get } from 'utils/axios';
import { clearItem } from 'utils/LocalStorage';
import { UpdateCurrentUser } from 'reducers/currentUser';

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state={
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
      },
      snackbarMessage: success
    }, () => { callback && callback() })
    
    changeUserCredential = dataToSubmit => Post(
      `/changeUserCredential`,
      dataToSubmit,
      this.changeUserCredentialSuccess,
      this.changeUserCredentialError,
      this.load
    )
    changeUserCredentialSuccess = payload => {
      console.log( payload.data )
      this.requestSuccess( 'Email/Password Updated')
    }
    changeUserCredentialError = error => this.requestError( error.message )

    updateProfile = dataToSubmit => Post(
      `/updateUser`,
      dataToSubmit,
      () => this.updateProfileSuccess( dataToSubmit.uid ),
      this.updateProfileError,
      this.load
    )
    updateProfileSuccess = uid => {
      this.getUserProfile( uid )
      this.requestSuccess( 'User Details Updated' )
    }

    getUserProfile = uid => Get(
      `/users-getUser?uid=${ uid }`,
      this.getUserProfileSuccess,
      this.getUserProfileError,
      this.load
    )
    getUserProfileSuccess = payload => {
      console.log( payload )
      // this.props.UpdateCurrentUser( payload )
      // storeItem( 'ADMIN_AUTHORIZATION_TOKEN', payload.uid )

      this.props.history.push( '/admin/statistic' )
    }
    getUserProfileError = error => this.requestError( error )

    signOutUser = () => {
      let auth = getAuth()
      signOut( auth ).then(() => {
        clearItem( 'ADMIN_AUTHORIZATION_TOKEN' )
        clearItem( 'CLIENT_AUTHORIZATION_TOKEN' )
        this.props.UpdateCurrentUser({})
        this.requestSuccess( 'Sign Out Successful' )
      }).catch((error) => {
        this.requestError( error.message )
      })
    }
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ... this.props }
            onLoading={ this.state.loading }

            signOutUser={ this.signOutUser }
            updateProfile={ this.updateProfile }
            changeUserCredential={ this.changeUserCredential }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
            />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps, {
    UpdateCurrentUser
  } )( WithHOC );
}

export default HOC