import firebase from 'firebase/compat/app';

export const initializeFirebase = () => {
  if (!firebase.apps.length) {
     firebase.initializeApp({
      apiKey: "AIzaSyDhwxDeH2Z6rtOj5YVkeZS6aUn72FX6sGQ",
      authDomain: "lockerja-e07a1.firebaseapp.com",
      projectId: "lockerja-e07a1",
      storageBucket: "lockerja-e07a1.appspot.com",
      messagingSenderId: "980816429113",
      appId: "1:980816429113:web:51bc3ba4af244e70118de8",
      measurementId: "G-09HEC8DE32"
    })
  }
}