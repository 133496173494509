import React, { useEffect, useState } from "react";
import _ from 'lodash'
import { Form, Modal, Button } from "react-bootstrap"

import { priceUnit } from "utils/dictionary";

const fieldData = [
  { label: 'Subscription Title', value: 'name', type: 'text' },
  { label: 'Description', value: 'description', type: 'textarea' },
  { label: 'Price', value: 'price', type: 'number' },
  { label: 'Price Unit', value: 'price_unit', type: 'select', options: priceUnit },
  { label: 'Status', value: 'status', type: 'select', options: [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 },
  ] },
  { label: 'Permissions', value: 'permissions', options: [
    { label: 'Access to Freelancer basic profile ', value: 'freelancer_profile' },
    { label: 'Access to Freelancer prefferd position', value: 'freelancer_preffered_position' },
    { label: 'Access to Freelancer selected sector', value: 'freelancer_selected_sector' },
    { label: 'Access to Freelancer expected salary', value: 'freelancer_expected_salary' },
    { label: 'Access to Freelancer year of industry experience', value: 'freelancer_industry_exp' },
    { label: 'Access to Freelancer previous job experience', value: 'freelancer_prev_job_exp' },
    { label: 'Access to Freelancer CV', value: 'freelancer_cv' },
    { label: 'Access to Freelancer industrial competency certificates', value: 'freelancer_certificates' },
    { label: 'Advertising', value: 'advertising' },
    // { label: 'Access to Freelancer Rating', value: 'freelancer_rating' },
    // { label: '10% Discount on continuous subscription', value: '10_percent_discount' },
    // { label: '20% Discount on continuous subscription', value: '20_percent_discount' },
    // { label: 'Access to basic employability statistic', value: 'employability_statistic' },
    // { label: 'No of User License', value: 'no_user_license', type: 'number' },
    // { label: 'No of Free Advertisement Per Month', value: 'no_free_ads', type: 'number' },
    { label: 'No. of Advertisement', value: 'no_ads', type: 'number' },
    { label: 'No. of Premium Job Listing', value: 'no_premium', type: 'number' },
  ] },
]

const SubscriptionForm = ({
  mode,
  selectedSubscription,

  setOpen,
  onClickSubmit,
}) => {

  const [ formData, setFormData ] = useState({
    name: '',
    description: '',
    price: '',
    price_unit: 1,
    status: 1,
    permissions: {},
  })

  useEffect(() => {
    if( !_.isEmpty( selectedSubscription ) ){
      setFormData( selectedSubscription )
    }
  }, [ selectedSubscription ])

  return(
    <Modal show={ true } onHide={() => setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{ _.capitalize( mode ) } Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          fieldData.map(( item, index ) => (
            <Form.Group key={ index } className="mb-3">
              <Form.Label>{ item.label }</Form.Label>
              {
                ( item.type === 'select' && item.value !== 'permissions' ) && (
                  <Form.Select
                    value={ formData[ item.value ] }
                    onChange={(e) => setFormData({ ...formData, [ item.value ]: e.target.value }) }>
                    {
                      item.options.map(( option, index ) => (
                        <option key={ index } value={ option.value }>{ option.label }</option>
                      ))
                    }
                  </Form.Select>
                )
              } 
              {
                ( item.type !== 'select' && item.value !== 'permissions' ) && (
                  <Form.Control
                    type={ item.type }
                    placeholder={ item.placeholder || item.label }
                    value={ formData[ item.value ] }
                    onChange={(e) =>  setFormData({ ...formData, [ item.value ]: e.target.value }) } />
                )
              }
              {
                item.value === 'permissions' && item.options.map(( option, index ) => {
                  if( option.type === 'number' ){
                    return(
                      <div className="d-flex align-items-center my-1">
                        <Form.Control
                          type={ item.type }
                          style={{ width: 50, height: 20, fontSize: 12 }}
                          value={ formData.permissions[ option.value ] }
                          onChange={(e) =>  setFormData({
                            ...formData,
                            permissions: {
                              ...formData.permissions,
                              [ option.value ]: e.target.value
                            }
                          }) } />
                        <span className="ml-2">{ option.label }</span>
                      </div>
                    )
                  } else {
                    return(
                      <Form.Check
                        id={`permissions-${ index }` }
                        type={ option.type || 'checkbox' }
                        label={ option.label } 
                        checked={ formData.permissions[ option.value ] }
                        onChange={(e) =>  setFormData({
                          ...formData,
                          permissions: {
                            ...formData.permissions,
                            [ option.value ]: e.target.checked
                          }
                        }) }/>
                    )
                  }
                })
              }
            </Form.Group>
          ))
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          disabled={ _.values( formData ).some( x => x === '' ) }
          variant="primary"
          onClick={ () => onClickSubmit( formData ) } >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SubscriptionForm;
