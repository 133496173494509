
import React from "react";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom"

import LoginPage from 'containers/LoginPage'
import LandingPage from "containers/LandingPage";
import AdminDashboard from 'containers/Dashboard/Admin'
import ClientDashboard from 'containers/Dashboard/Client'

import AdminStatistic from 'containers/Dashboard/Admin/Statistic'
import AdminAnnouncement from 'containers/Dashboard/Admin/Announcement'
import AdminUserManagement from 'containers/Dashboard/Admin/UserManagement'
import AdminJobListingManagement from 'containers/Dashboard/Admin/JobListingManagement'
import AdminSubscriptionManagement from 'containers/Dashboard/Admin/SubscriptionManagement'

import ClientStatistic from 'containers/Dashboard/Client/Statistic'
import ClientListings from 'containers/Dashboard/Client/ListingCenter'
import ClientWorkers from 'containers/Dashboard/Client/WorkerManagement'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/400.css';
import './App.css';

const App = () => {

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={ <LandingPage /> } initialPath />
        <Route exact path='admin' element={ <AdminDashboard /> } >
          <Route path="*" element={ <Navigate replace to="/admin" /> } />
          <Route path="login" element={ <LoginPage type={ 'admin' } /> } />
          <Route path="statistic" element={ <AdminStatistic /> } />
          <Route path="announcement" element={ <AdminAnnouncement /> } />
          <Route path="user-management" element={ <AdminUserManagement /> } />
          <Route path="subscription" element={ <AdminSubscriptionManagement /> } />
          <Route path="job-listing" element={ <AdminJobListingManagement /> } />
        </Route>
        <Route exact path='client' element={ <ClientDashboard /> } >
          <Route path="*" element={ <Navigate replace to="/client" /> } />
          <Route path="login" element={ <LoginPage type={ 'client' } /> } />
          <Route path="workers" element={ <ClientWorkers /> } />
          <Route path="listings" element={ <ClientListings /> } />
          <Route path="statistic" element={ <ClientStatistic /> } />
        </Route>
        <Route path="*" element={ <Navigate replace to="/" /> } />
      </Routes>
    </div>
  );
}

export default App;
