import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Outlet, useNavigate } from "react-router-dom"

import TopNav from 'components/TopNav'
import NavBar from 'components/NavBar'

import { getItem } from 'utils/LocalStorage'

const AdminDashboard = ( props ) => {
  
  const navigate = useNavigate()
  const [ sidebar, setSidebar ] = useState( true )

  useEffect( () => {
    if( getItem( 'ADMIN_AUTHORIZATION_TOKEN' ) === null ){
      navigate( 'login' )
    }
  }, [ props ])

  return(
    <div>
      {
        !window.location.href.includes( 'login' ) && (
          <>
            <TopNav
              sidebar={ sidebar }
              language={ '' }
              setSidebar={ setSidebar }
              onNavigate={ navigate } />
            <NavBar
              sidebar={ sidebar }
              user={ props.data?.currentUser }
              language={ '' } />
            <div className='module-container'>
              <Outlet context={{
                ...props,
                onNavigate: navigate,
              }}/>
            </div>
          </>
        )
      }
      {
        window.location.href.includes( 'login' ) && (
          <Outlet context={{ ...props, onNavigate: navigate }} />
        )
      }
    </div>
  )
}

const mapStateToProps = state => ({ data: state })
export default connect ( mapStateToProps )( AdminDashboard );